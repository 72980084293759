import React from "react";
import userContext from "../userContext";
import { useHistory, Link } from "react-router-dom";
import madivalogo from "../images/madivalogo.png";
import km77logo from "../images/km77-logo.png";
import { Amplitude } from "@amplitude/react-amplitude";

export default function PoliticaCookies() {
  const session = React.useContext(userContext);

  let history = useHistory();
  React.useEffect(() => {}, [session]);

  return (
    <div className="m-0 p-0">
      <Amplitude>
        {({ logEvent }) => (
          <div className="m-4">
            <Link to="/">
              <img
                src={madivalogo}
                alt="Madiva"
                className="box-border p-3 w-20 md:w-36 float-right"
              />
              <img
                src={km77logo}
                alt="Km77"
                className="box-border p-3 w-20 md:w-36 float-right"
              />
            </Link>
            <div className="font-mulish leading-7">
              <div className="mx-4">
                <h1 className="my-4 text-2xl text-blue-800 font-bold">
                  Condiciones generales de contratación
                </h1>
                <div className="mb-2">
                  KM77 por su propia cuenta o la de un tercero
                  contratado para la prestaci&oacute;n de servicios de
                  medici&oacute;n, pueden utilizar Cookies y otros mecanismos
                  similares de almacenamiento y recuperaci&oacute;n de datos en
                  equipos terminales (en adelante, &ldquo;<b>Cookies</b>&rdquo;)
                  cuando el Usuario navega por el sitio web ubicado en la URL
                  [&hellip;] (en adelante, el &ldquo;<b>Sitio Web</b>&rdquo;)
                  con la finalidad de permitir y facilitar la interacci&oacute;n
                  del usuario con el mismo. Los servicios de estad&iacute;sticas
                  &ndash;propios o ajenos&ndash; empleados por el Sitio Web (en
                  adelante, el &ldquo;<b>Sitio Web</b>&rdquo;) pueden emplear
                  "Cookies" con la finalidad de establecer m&eacute;tricas y
                  patrones de uso del Sitio Web.
                </div>
                <div className="mb-2">
                  Mediante el uso de las Cookies resulta posible que el servidor
                  donde se encuentra alojado el Sitio Web reconozca el navegador
                  Web utilizado por el Usuario, con la finalidad de que la
                  navegaci&oacute;n sea m&aacute;s sencilla, permitiendo, por
                  ejemplo, el acceso a los usuarios que se hayan registrado
                  previamente, acceder a las &aacute;reas, servicios,
                  promociones o concursos reservados exclusivamente a ellos sin
                  tener que registrarse en cada visita. Se utilizan
                  tambi&eacute;n para medir la audiencia y par&aacute;metros del
                  tr&aacute;fico, as&iacute; como para controlar el progreso y
                  n&uacute;mero de entradas.
                </div>
                <div className="mb-2">
                  <b>&iquest;QU&Eacute; SON LAS COOKIES?</b>
                </div>
                <div className="mb-2">
                  Una Cookie es un fichero que se descarga en su ordenador al
                  acceder a determinadas p&aacute;ginas web. Las Cookies
                  permiten a una p&aacute;gina web, entre otras cosas, almacenar
                  y recuperar informaci&oacute;n sobre los h&aacute;bitos de
                  navegaci&oacute;n de un usuario o de su equipo y, dependiendo
                  de la informaci&oacute;n que contengan y de la forma en que
                  utilice su equipo, pueden utilizarse para reconocer al
                  usuario.
                </div>
                <div className="mb-2">
                  <b>&iquest;POR QU&Eacute; SON IMPORTANTES LAS COOKIES?</b>
                </div>
                <div className="mb-2">
                  Las Cookies son &uacute;tiles por varios motivos. Desde un
                  punto de vista t&eacute;cnico, permiten que las p&aacute;ginas
                  web funcionen de forma m&aacute;s &aacute;gil y adaptada a las
                  preferencias del Usuario, como por ejemplo almacenar su idioma
                  o la moneda de su pa&iacute;s. Adem&aacute;s, ayudan a los
                  responsables de los sitios web a mejorar los servicios que
                  ofrecen, gracias a la informaci&oacute;n estad&iacute;stica
                  que recogen a trav&eacute;s de ellas. Finalmente, sirven para
                  hacer m&aacute;s eficiente la publicidad que muestran los
                  responsables de un sitio web.
                </div>
                <div className="mb-2">
                  <b>TIPOS DE COOKIES</b>
                </div>
                <div className="mb-2">Por entidad gestora del dominio</div>
                <ul class="list-disc ml-4 md:ml-8 mb-2">
                  <li>Cookies propias</li>
                  <li>Cookies de terceros</li>
                </ul>
                <div className="mb-2">Por su tiempo de permanencia</div>
                <ul class="list-disc ml-4 md:ml-8 mb-2">
                  <li>Cookies de sesi&oacute;n</li>
                  <li>Cookies persistentes</li>
                </ul>
                <div className="mb-2">Por su finalidad</div>
                <ul class="list-disc ml-4 md:ml-8 mb-2">
                  <li>Cookies t&eacute;cnicas</li>
                  <li>Cookies de personalizaci&oacute;n</li>
                  <li>Cookies de an&aacute;lisis</li>
                  <li>Cookies de publicidad</li>
                  <li>Cookies de publicidad comportamental</li>
                  <li>Cookies de redes sociales</li>
                </ul>
                <div className="mb-2">
                  <b>
                    ¿QUÉ TIPOS DE COOKIES UTILIZA ESTE SITIO WEB Y CUÁLES SON
                    SUS FINALIDADES?
                  </b>
                </div>
                <ol type="list-decimal ml-4 md:ml-8 mb-2">
                  <li>
                    <b>Cookies t&eacute;cnicas y de sesi&oacute;n:</b> son un
                    tipo de Cookies dise&ntilde;adas y utilizadas para recabar y
                    almacenar datos mientras el usuario accede a una
                    p&aacute;gina web. Como su propio nombre indica, estas
                    Cookies se almacenan en el terminal hasta que finalice la
                    sesi&oacute;n de navegaci&oacute;n del usuario.
                  </li>
                  <li>
                    <b>Cookies de an&aacute;lisis</b>: son aquellas que, bien
                    tratadas por nosotros o por terceros, nos permiten
                    cuantificar el n&uacute;mero de usuarios y as&iacute;
                    realizar la medici&oacute;n y an&aacute;lisis
                    estad&iacute;stico de la utilizaci&oacute;n que hacen los
                    usuarios del servicio ofertado. Para ello, se analiza su
                    navegaci&oacute;n en nuestra p&aacute;gina web con el fin de
                    mejorar la oferta de productos o servicios que le ofrecemos.
                  </li>
                </ol>
                <table className="table-auto border-collapse border border-blue-800 my-2">
                  <thead>
                    <tr className="bg-blue-100">
                      <th className="border border-blue-800 p-2 font-bold">
                        Propiedad
                      </th>
                      <th className="border border-blue-800 p-2 font-bold">
                        Cookie
                      </th>
                      <th className="border border-blue-800 p-2 font-bold">
                        Finalidad
                      </th>
                      <th className="border border-blue-800 p-2 font-bold">
                        Plazo
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-blue-800 p-2">
                        gstatic.com
                      </td>
                      <td className="border border-blue-800 p-2">CONSENT</td>
                      <td className="border border-blue-800 p-2">
                        Rastreador de consentimiento de cookies de Google
                      </td>
                      <td className="border border-blue-800 p-2">en 17 años</td>
                    </tr>
                  </tbody>
                </table>
                <div className="mb-2">
                  Esta informaci&oacute;n ser&aacute; actualizada tan pronto
                  como sea posible cuando cambien los servicios ofrecidos en
                  esta web. Sin embargo, es posible que la tabla de
                  informaci&oacute;n no incluya temporalmente a una cookie,
                  etiqueta u otro dispositivo similar, debido a una
                  actualizaci&oacute;n, aunque siempre habr&aacute; dispositivos
                  con finalidades id&eacute;nticas a aquellas incluidas en este
                  gr&aacute;fico.
                </div>
                <div className="mb-2">
                  <b>
                    &iquest;QUI&Eacute;NES SON LOS DESTINATARIOS DE LA
                    INFORMACI&Oacute;N? &iquest;D&Oacute;NDE EST&Aacute;N
                    UBICADOS?
                  </b>
                </div>
                <div className="mb-2">
                  La informaci&oacute;n recogida mediante las Cookies puede ser
                  utilizada tanto por KM77, como por terceras empresas
                  colaboradoras, como operadores y agentes de an&aacute;lisis
                  web, para los fines descritos en la secci&oacute;n anterior.
                </div>
                <div className="mb-2">
                  Algunos de estos terceros pueden estar situados en terceros
                  pa&iacute;ses cuya legislaci&oacute;n no ofrece un nivel de
                  protecci&oacute;n de datos equivalente; no obstante, el
                  usuario podr&aacute; consultar las garant&iacute;as adecuadas
                  que ofrecen para que las transferencias internacionales de
                  datos se realicen teniendo en cuenta los derechos y libertades
                  de los interesados.
                </div>
                <div className="mb-2">
                  El Usuario puede conocer m&aacute;s sobre estos terceros,
                  as&iacute; como los tipos de Cookies que instalan, los fines
                  para los que procesan los datos, las garant&iacute;as que
                  ofrecen para llevar a cabo las transferencias internacionales,
                  los plazos de conservaci&oacute;n y c&oacute;mo se pueden
                  configurar las preferencias con respecto a ellos, a
                  trav&eacute;s de sus pol&iacute;ticas de privacidad. En
                  cualquier caso, KM77 no se hace responsable de la
                  exactitud y el contenido de las pol&iacute;ticas de privacidad
                  ofrecidas por esos terceros en sus sitios web.
                </div>
                <div className="mb-2">
                  <b>GESTI&Oacute;N DE LAS COOKIES</b>
                </div>
                <div className="mb-2">
                  Teniendo en cuenta la informaci&oacute;n facilitada a
                  trav&eacute;s de la presente Pol&iacute;tica de Cookies, a
                  continuaci&oacute;n, te ofrecemos informaci&oacute;n sobre
                  c&oacute;mo puedes gestionar las Cookies utilizadas en&nbsp;la
                  web&nbsp;a trav&eacute;s de las diferentes opciones que
                  ofrecen los navegadores m&aacute;s comunes (Explorer, Firefox,
                  Chrome, Safari y Opera).&nbsp;En la funci&oacute;n
                  &ldquo;Ayuda&rdquo; de la mayor&iacute;a de los navegadores se
                  indica c&oacute;mo configurar su navegador para que no acepte
                  Cookies, para que le notifique cada vez que reciba una nueva
                  Cookie, as&iacute; como para desactivar completamente todas
                  las Cookies.
                </div>
                <div className="mb-2">
                  As&iacute;, por ejemplo, puede encontrar informaci&oacute;n
                  sobre c&oacute;mo gestionar sus preferencias sobre Cookies en
                  los navegadores m&aacute;s habituales a trav&eacute;s de los
                  enlaces siguientes:
                </div>
                <div className="mb-2">
                  Firefox desde{" "}
                  <a
                    className="text-blue-800"
                    href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&amp;redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we"
                  >
                    aqu&iacute;
                  </a>
                  .
                </div>
                <div className="mb-2">
                  Chrome desde{" "}
                  <a
                    className="text-blue-800"
                    href="https://support.google.com/chrome/answer/95647?hl=es"
                  >
                    aqu&iacute;
                  </a>
                  .
                </div>
                <div className="mb-2">
                  Explorer desde{" "}
                  <a
                    className="text-blue-800"
                    href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies"
                  >
                    aqu&iacute;
                  </a>
                  .
                </div>
                <div className="mb-2">
                  Safari desde{" "}
                  <a
                    className="text-blue-800"
                    href="https://support.apple.com/kb/ph5042?locale=es_ES"
                  >
                    aqu&iacute;
                  </a>
                  .
                </div>
                <div className="mb-2">
                  Opera desde{" "}
                  <a
                    className="text-blue-800"
                    href="https://help.opera.com/en/latest/security-and-privacy/"
                  >
                    aqu&iacute;
                  </a>
                  .
                </div>
                <div className="mb-2">
                  Si tu navegador no se encuentra entre los que te hemos
                  listado, consulta la documentaci&oacute;n espec&iacute;fica de
                  tu navegador.
                </div>
                <div className="mb-2">
                  Si se impide la instalaci&oacute;n de todas las Cookies de la
                  p&aacute;gina web, algunas funcionalidades de la misma,
                  as&iacute; como algunos contenidos pueden verse afectados.
                </div>
                <div className="mb-2">
                  <b>INFORMACI&Oacute;N ADICIONAL </b>
                </div>
                <div className="mb-2">
                  Puede ponerse en contacto con KM77, a trav&eacute;s de{" "}
                  <a className="text-blue-800" href="mailto:informes.segundamano@km77.com">
                    {" "}
                    informes.segundamano@km77.com
                  </a>, donde podr&aacute; escribir para ejercer sus derechos de
                  acceso, rectificaci&oacute;n o supresi&oacute;n,
                  limitaci&oacute;n de su tratamiento, oposici&oacute;n,
                  portabilidad y a oponerse a decisiones individuales
                  automatizadas.
                </div>
                <div className="mb-2">
                  No obstante lo anterior, en la medida en que la
                  informaci&oacute;n procesada est&eacute; relacionada
                  &uacute;nicamente con c&oacute;digos o identificadores
                  &uacute;nicos de dispositivos o navegadores utilizados por el
                  usuario, y no est&eacute; relacionada con datos que
                  identifiquen directamente al usuario, los derechos anteriores
                  no ser&aacute;n aplicables a menos que el usuario proporcione
                  informaci&oacute;n adicional que permita su
                  identificaci&oacute;n.
                </div>
                <div className="mb-2">
                  Si desea conocer informaci&oacute;n adicional sobre el
                  tratamiento de sus datos, puede consultar la{" "}
                  <Link
                    className="text-blue-800"
                    to="/privacidad"
                    //onClick={() => logEvent("Política de privacidad")}
                  >
                    Política de privacidad
                  </Link>
                  .
                </div>
                <div className="mb-2">
                  Fecha de &uacute;ltima actualizaci&oacute;n: 18/08/2021
                </div>
                <p className="">
                  <a
                    className="flex text-blue-800 justify-start items-center"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      history.go(-1);
                    }}
                  >
                    <svg
                      class="fill-current text-blue-800 p-0 m-0 w-8 h-8"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z" />
                    </svg>
                    Volver
                  </a>
                </p>{" "}
              </div>
            </div>
          </div>
        )}
      </Amplitude>
    </div>
  );
}
