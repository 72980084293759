import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import amplitude from "amplitude-js";
import { AmplitudeProvider } from "@amplitude/react-amplitude";
import Footer from "./Footer";
import Test from "./Test";
import Success from "./Success";
import Cancel from "./Cancel";
import AvisoLegal from "./AvisoLegal";
import PoliticaCookies from "./PoliticaCookies";
import PoliticaPrivacidad from "./PoliticaPrivacidad";
import FAQS from "./FAQS";
import Contacto from "./Contacto";
import ComoLoHacemos from "./ComoLoHacemos";
import TerminosCondiciones from "./TerminosCondiciones";
import CondicionesContratacion from "./Condiciones";

const AMPLITUDE_KEY = "38fecfa9d9de4f39855b26ad3f397216";
export default function Redirector(props) {
  let amplitudeClient = amplitude.getInstance();
  amplitudeClient.init(AMPLITUDE_KEY, null, {
    includeUtm: true,
    includeGclid: true,
    includeReferrer: true,
  });

  return (
    <Router>
      <AmplitudeProvider amplitudeInstance={amplitudeClient}>
        <Switch>
          <Route path="/images/*"></Route>
          <Route exact path="/public/success">
            <Success />
          </Route>
          <Route exact path="/public/cancel">
            <Cancel />
          </Route>
          <Route exact path="/steps/:step">
            <Test />
          </Route>
          <Route exact path="/avisolegal">
            <AvisoLegal />
          </Route>
          <Route exact path="/cookies">
            <PoliticaCookies />
          </Route>
          <Route exact path="/privacidad">
            <PoliticaPrivacidad />
          </Route>
          <Route exact path="/condicionesuso">
            <TerminosCondiciones />
          </Route>
          <Route exact path="/condicionescontratacion">
            <CondicionesContratacion />
          </Route>
          <Route exact path="/faqs">
            <FAQS />
          </Route>
          <Route exact path="/contacto">
            <Contacto />
          </Route>
          <Route exact path="/comolohacemos">
            <ComoLoHacemos />
          </Route>
          <Route path="/">
            <Test />
          </Route>
        </Switch>
        <Footer />
        <CookieConsent
          enableDeclineButton
          overlay="true"
          location="top"
          buttonText="Aceptar"
          declineButtonText="Rechazar"
          ariaAcceptLabel="Aceptar"
          ariaDeclineLabel="Rechazar"
          cookieName="Km77MadivaCookie"
          style={{ background: "#4b6d8f" }}
          buttonStyle={{ background: "#e5a556", color: "#fff" }}
          declineButtonStyle={{ background: "#a4a4a4", color: "#fff" }}
          containerClasses="row"
          buttonClasses="buttonCookie"
          declineButtonClasses="buttonCookie"
          expires={365}
        >
          Este sitio web utiliza cookies propias de KM77 y Madiva y de terceros
          para analizar los servicios prestados y las visitas realizadas y así
          poder mejorar nuestros productos en base a la información obtenida de
          tus hábitos de navegación. <br /> Puedes obtener más información y
          configurar tus preferencias <Link to="/cookies">AQUÍ</Link>.
        </CookieConsent>
      </AmplitudeProvider>
    </Router>
  );
}
