import React from "react";
import { useHistory, Link } from "react-router-dom";
import madivalogo from "../images/madivalogo.png";
import km77logo from "../images/km77-logo.png";
import { Amplitude } from "@amplitude/react-amplitude";

export default function AvisoLegal() {
  let history = useHistory();

  return (
    <div className="m-0 p-0">
      <Amplitude>
        {({ logEvent }) => (
          <div className="m-4">
            <Link to="/">
              <img
                src={madivalogo}
                alt="Madiva"
                className="box-border p-3 w-20 md:w-36 float-right"
              />
              <img
                src={km77logo}
                alt="Km77"
                className="box-border p-3 w-20 md:w-36 float-right"
              />
            </Link>
            <div className="font-mulish leading-7">
              <div className="mx-4">
                <h1 className="my-4 text-2xl text-blue-800 font-bold">
                  Aviso Legal
                </h1>
                <div className="mb-2">
                  El responsable de la prestaci&oacute;n del servicio ofrecido a
                  trav&eacute;s de este Sitio Web es RUEDAS DE PRENSA, S.L.
                  (&ldquo;<strong>KM77</strong>&rdquo;), cuyos datos
                  identificativos son los siguientes:
                </div>
                <div>
                  <div className="list-disc ml-3 mb-2">
                    <li>NIF: B82262254.</li>
                    <li>
                      Domicilio: Calle Golfo de Salónica, 49 1ºJ, con
                      C&oacute;digo Postal 28033, Madrid (Espa&ntilde;a).
                    </li>
                    <li>Tel&eacute;fono de contacto: 915130495</li>
                    <li>
                      Direcci&oacute;n de correo electr&oacute;nico de contacto:{" "}
                      <a className="text-blue-800" href="mailto: dpo@km77.com">
                        dpo@km77.com
                      </a>
                    </li>
                    <li>
                      Datos de inscripci&oacute;n en el Registro Mercantil: Hoja
                      M-228883, Folio 44, Tomo 13.967
                    </li>
                  </div>
                </div>
                <div className="mt-2">
                  Puedes conocer m&aacute;s informaci&oacute;n sobre los{" "}
                  <Link
                    className="text-blue-800"
                    to="/condicionesuso"
                    //onClick={() => logEvent("Términos y Condidiones")}
                  >
                    Términos y Condidiones
                  </Link>{" "}
                  de uso del servicio prestado por KM77, sobre la{" "}
                  <Link
                    className="text-blue-800"
                    to="/privacidad"
                    //onClick={() => logEvent("Política de privacidad")}
                  >
                    Política de privacidad
                  </Link>{" "}
                  y/o sobre la{" "}
                  <Link
                    className="text-blue-800"
                    to="/cookies"
                    //onClick={() => logEvent("Política de cookies")}
                  >
                    Política de cookies
                  </Link>{" "}
                  en las secciones correspondientes.
                </div>
                <p className="">
                  <a
                    className="flex text-blue-800 justify-start items-center"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      history.go(-1);
                    }}
                  >
                    <svg
                      class="fill-current text-blue-800 p-0 m-0 w-8 h-8"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z" />
                    </svg>
                    Volver
                  </a>
                </p>
              </div>
            </div>
          </div>
        )}
      </Amplitude>
    </div>
  );
}
