import React from "react";
import userContext from "../userContext";
import { useHistory, Link } from "react-router-dom";
import madivalogo from "../images/madivalogo.png";
import km77logo from "../images/km77-logo.png";
import { Amplitude } from "@amplitude/react-amplitude";

export default function PoliticaPrivacidad() {
  const session = React.useContext(userContext);

  let history = useHistory();
  React.useEffect(() => {}, [session]);

  return (
    <div className="m-0 p-0">
      <Amplitude>
        {({ logEvent }) => (
          <div className="m-4">
            <Link to="/">
              <img
                src={madivalogo}
                alt="Madiva"
                className="box-border p-3 w-20 md:w-36 float-right"
              />
              <img
                src={km77logo}
                alt="Km77"
                className="box-border p-3 w-20 md:w-36 float-right"
              />
            </Link>
            <div className="font-mulish leading-7">
              <div className="mx-4">
                <h1 className="my-4 text-2xl text-blue-800 font-bold">
                  Pol&iacute;tica de privacidad
                </h1>
                <h2 className="my-4 text-xl text-blue-800 font-bold">
                  PRIMERA CAPA
                </h2>
                <table className="table-auto border-collapse border border-blue-800 my-2">
                  <thead>
                    <tr className="bg-blue-100">
                      <th className="border border-blue-800 p-2 font-bold">
                        EP&Iacute;GRAFE
                      </th>
                      <th
                        className="border border-blue-800 p-2 font-bold"
                        colspan="2"
                      >
                        INFORMACI&Oacute;N B&Aacute;SICA
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-blue-800 p-2">
                        Responsable del tratamiento
                      </td>
                      <td className="border border-blue-800 p-2">
                        <p>
                          RUEDAS DE PRENSA, S.L. (&ldquo;<b>KM77</b>&rdquo;)
                        </p>
                        <p>
                          Calle Golfo de Sal&oacute;nica, 49 1ºJ con
                          C&oacute;digo Postal 28033, Madrid (Espa&ntilde;a)
                        </p>
                        <p>NIF B82262254</p>
                        <p>
                          <a
                            className="text-blue-800"
                            href="mailto: lopd@km77.com"
                          >
                            lopd@km77.com
                          </a>
                        </p>
                      </td>                      
                    </tr>
                    <tr>
                      <td className="border border-blue-800 p-2">
                        Información del Delegado de Protección de Datos
                      </td>
                      <td className="border border-blue-800 p-2">
                        <p>Grupo Adaptalia Legal Formativo, S.L.</p>
                        <p>C/ Julián Camarillo 26, 4º Izq, 28036, Madrid.</p>
                        <p>
                          <a
                            className="text-blue-800"
                            href="mailto: dpo@km77.com"
                          >
                            dpo@km77.com
                          </a>
                        </p>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="border border-blue-800 p-2" rowSpan="2">
                        Finalidades de tratamiento de sus datos personales
                      </td>
                      <td className="border border-blue-800 p-2" colspan="2">
                        <ul className="list-disc ml-4 md:ml-8">
                          <li>
                            Gestionar y mantener la relaci&oacute;n comercial.
                          </li>
                          <li>
                            Atender las solicitudes de informaci&oacute;n.
                          </li>
                          <li>
                            Remitir comunicaciones comerciales propias o de
                            terceros, siempre a trav&eacute;s de KM77.
                          </li>
                          <li>
                            Ofrecer comunicaciones comerciales y servicios
                            personalizados en base a la obtención de perfiles y
                            labores de segmentación.
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-blue-800 p-2" colspan="2">
                        <u>Plazos de conservaci&oacute;n</u>: mientras se
                        mantenga la relaci&oacute;n comercial o no se solicite
                        su supresi&oacute;n por el interesado, no deban
                        eliminarse por ser necesarios para el cumplimiento de
                        una obligaci&oacute;n legal o para la
                        formulaci&oacute;n, ejercicio y defensa de
                        reclamaciones.
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-blue-800 p-2">Origen</td>
                      <td className="border border-blue-800 p-2" colspan="2">
                        <ul className="list-disc ml-4 md:ml-8">
                          <li>Facilitados por el propio usuario.</li>
                          <li>
                            Obtenidos a trav&eacute;s de la web, o en base a
                            consumos realizados.
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-blue-800 p-2">
                        Bases Legitimaci&oacute;n
                      </td>
                      <td className="border border-blue-800 p-2" colSpan="2">
                        <ul className="list-disc ml-4 md:ml-8">
                          <li>
                            {" "}
                            La ejecuci&oacute;n del contrato con el usuario.
                          </li>
                          <li> Las obligaciones legales de KM77.</li>
                          <li>
                            {" "}
                            El inter&eacute;s leg&iacute;timo de KM77 en relaci&oacute;n con comunicaciones
                            comerciales relacionadas con sus productos o
                            servicios.
                          </li>
                          <li>
                            {" "}
                            El consentimiento del usuario, para el env&iacute;o
                            de comunicaciones comerciales de terceros, siempre a
                            trav&eacute;s de KM77 o la
                            instalaci&oacute;n de sistemas de seguimiento
                            seg&uacute;n la{" "}
                            <Link
                              className="text-blue-800"
                              to="/cookies"
                              //onClick={() => logEvent("Política de cookies")}
                            >
                              Política de cookies
                            </Link>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-blue-800 p-2">
                        Destinatarios de cesiones
                      </td>
                      <td className="border border-blue-800 p-2" colSpan="2">
                        Todas son necesarias para el cumplimiento de las
                        finalidades anteriormente indicadas, o bien se llevan a
                        cabo en cumplimiento de una obligaci&oacute;n legal.
                        Administraciones p&uacute;blicas, proveedores para la
                        prestaci&oacute;n de los servicios solicitados, empresas
                        y entidades colaboradoras.
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-blue-800 p-2" rowSpan="4">
                        Derechos de los Participantes
                      </td>
                      <td className="border border-blue-800 p-2">
                        <p>
                          Podr&aacute;n ejercitarse ante KM77 en las siguientes
                          direcciones:
                        </p>
                        <p>
                          <a href="mailto: informes.segundamano@km77.com">informes.segundamano@km77.com</a>
                        </p>
                        <p>
                          Calle Golfo de Sal&oacute;nica, 49 1ºJ, con
                          C&oacute;digo Postal 28033, Madrid (Espa&ntilde;a)
                        </p>
                      </td>                      
                    </tr>
                    <tr>
                      <td className="border border-blue-800 p-2" colSpan="2">
                        Le informamos que puede ejercitar, en su caso, los
                        derechos de acceso, rectificaci&oacute;n o
                        supresi&oacute;n, limitaci&oacute;n del tratamiento,
                        oposici&oacute;n, a la portabilidad de los datos o a no
                        ser objeto de una decisi&oacute;n basada
                        &uacute;nicamente en el tratamiento automatizado,
                        as&iacute; como a retirar el consentimiento prestado.
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-blue-800 p-2" colSpan="2">
                        Puede presentar reclamaciones ante la Agencia
                        Espa&ntilde;ola de Protecci&oacute;n de Datos (
                        <a href="http://www.agpd.es">www.agpd.es</a>) o la
                        autoridad auton&oacute;mica que resulte competente
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-blue-800 p-2" colSpan="2">
                        M&aacute;s informaci&oacute;n en la Pol&iacute;tica de
                        Privacidad completa del presente documento.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h2 className="my-4 text-xl text-blue-800 font-bold">
                  SEGUNDA CAPA
                </h2>
                <ol className="list-decimal ml-4 md:ml-8">
                  <li className="">
                    <b>
                      Identificaci&oacute;n del responsable del tratamiento.
                    </b>
                  </li>
                  <p>
                    KM77 es responsable del tratamiento de tus datos
                    de car&aacute;cter personal a los efectos de prestarte a
                    trav&eacute;s del sitio web ubicado en la URL [&hellip;] (el
                    &ldquo;<b>Sitio Web</b>&rdquo;) el servicio de
                    valoraci&oacute;n de veh&iacute;culos y obtenci&oacute;n de
                    informes t&eacute;cnicos sobre los mismos.
                  </p>
                  <p>
                    KM77 es una sociedad domiciliada en la Calle Golfo de
                    Sal&oacute;nica, 49 1ºJ 28033 Madrid (España), y su
                    n&uacute;mero de NIF es B82262254 (&ldquo;<b>KM77</b>
                    &rdquo;).
                  </p>                  
                  <p>
                    En KM77 estamos comprometidos con el derecho
                    fundamental a la protecci&oacute;n de tus datos personales y
                    esta Pol&iacute;tica de Privacidad tiene como finalidad
                    informarte sobre tus derechos en virtud del Reglamento
                    General de Protecci&oacute;n de Datos (&ldquo;<b>RGPD</b>
                    &rdquo;).
                  </p>
                  <p>
                    Te informamos de que podrás contactar con nosotros para
                    cualquier duda o consulta que quieras hacer en relación con
                    el tratamiento de tus datos, a través del siguiente correo
                    electrónico <a href="mailto: informes.segundamano@km77.com">informes.segundamano@km77.com</a>.
                  </p>
                  <li className="">
                    <b>
                      &iquest;Qu&eacute; informaci&oacute;n recogeremos de ti?
                    </b>
                  </li>
                  <p>
                    Debes saber que existen distintas v&iacute;as para recoger
                    datos personales:
                  </p>
                  <ol className="list-decimal ml-4 md:ml-8">
                    <li>
                      <b>Los datos que nos aportas de manera directa. </b>
                    </li>
                    <p>
                      Recabaremos informaci&oacute;n sobre ti cuando quieras
                      participar en el servicio que ofrecemos a trav&eacute;s de
                      nuestro sitio web, o cuando nos contactes a trav&eacute;s
                      de los canales habilitados para ello, tales como la
                      direcci&oacute;n de contacto.
                    </p>
                    <p>
                      Cuando contactes con nosotros a trav&eacute;s de dichos
                      canales, solicitaremos tu consentimiento expreso para
                      recabar tus datos. En particular, cuando quieras adquirir
                      alguno de los informes sobre valoraci&oacute;n y
                      caracter&iacute;sticas de un veh&iacute;culo,
                      necesitaremos tu direcci&oacute;n de correo
                      electr&oacute;nico, tu nombre y apellidos y la
                      informaci&oacute;n asociada a tu tarjeta de cr&eacute;dito
                      para poder identificarte y remitirte los informes
                      adquiridos.
                    </p>
                    <p>
                      Todos los campos que aparezcan se&ntilde;alados con un
                      asterisco (*) en los formularios que se te faciliten en
                      los canales ser&aacute;n de obligada
                      cumplimentaci&oacute;n, de tal modo que la omisi&oacute;n
                      de alguno de ellos podr&iacute;a comportar la
                      imposibilidad de que se te puedan facilitar los servicios
                      solicitados. Deber&aacute;s proporcionar
                      informaci&oacute;n ver&iacute;dica, quedando prohibida la
                      suplantaci&oacute;n de identidad o el uso de alias o
                      nombres an&oacute;nimos.&nbsp;
                    </p>
                    <p>
                      Asimismo, al hacer &ldquo;click&rdquo; en el bot&oacute;n
                      &ldquo;Acepto&rdquo; (o equivalente) incorporado en los
                      citados formularios, declaras que la informaci&oacute;n y
                      los datos que en ellos has facilitado son exactos y
                      veraces, y que est&aacute;s de acuerdo con el tratamiento
                      que realizamos de los mismos.
                    </p>
                    <li>
                      <b>Los datos obtenidos de manera indirecta.</b>
                    </li>
                    <p>
                      Cuando navegas, pueden instalarse en tu dispositivo
                      distintas cookies y otros dispositivos de seguimiento, tal
                      y como te explicamos en nuestra Pol&iacute;tica de
                      Cookies.
                    </p>
                  </ol>
                  <li className="">
                    <b>&iquest;Cu&aacute;l es el origen de tus datos? </b>
                  </li>
                  <p>
                    Consideramos que todos los datos tratados por KM77 nos los has aportado libremente.
                  </p>
                  <p>
                    En caso de que los datos personales aportados sean de un
                    tercero, garantizas que le has informado de esta
                    Pol&iacute;tica de Privacidad y has obtenido su
                    autorizaci&oacute;n para facilitar los datos a KM77 
                    con las finalidades se&ntilde;aladas anteriormente.
                    Igualmente, garantizas que los datos aportados son exactos y
                    actualizados, y te haces responsable de cualquier
                    da&ntilde;o o perjuicio, directo o indirecto, que pudiera
                    ocasionarse como consecuencia del incumplimiento de tal
                    obligaci&oacute;n.
                  </p>
                  <li className="">
                    <b>
                      &iquest;C&oacute;mo usaremos tu informaci&oacute;n
                      personal? &iquest;Qu&eacute; servicios te prestamos?
                    </b>
                  </li>
                  <p>
                    KM77 act&uacute;an como responsables del
                    tratamiento de datos personales para las siguientes
                    finalidades y de acuerdo con las siguientes bases
                    legitimadoras:
                  </p>
                  <ul className="list-disc ml-4 md:ml-8">
                    <li>
                      <b>En base a la gesti&oacute;n</b>
                      <b>
                        {" "}
                        de la relaci&oacute;n contractual o precontractual:
                      </b>
                    </li>
                    <ol className="list-decimal ml-4 md:ml-8">
                      <li>
                        Gestionar la adquisici&oacute;n de los productos que
                        comercializamos en nuestro Sitio Web y su
                        correspondiente pago, atender cualquier tipo de
                        incidencia o petici&oacute;n derivada de la misma, y
                        llevar a cabo cualquier gesti&oacute;n relacionada con
                        el env&iacute;o o servicio postventa.
                      </li>
                      <li>
                        Prestarte los servicios que nos hayas requerido y
                        atender tus peticiones de informaci&oacute;n, as&iacute;
                        como facilitarte el ejercicio de tus derechos cuando nos
                        contactas mediante los canales habilitados para ello,
                        tales como email, tel&eacute;fono o cualquier otro medio
                        de contacto que pongamos a tu disposici&oacute;n.
                      </li>
                    </ol>
                    <p>
                      En particular, KM77 trata los datos personales de los
                      usuarios de este Sitio Web para gestionar el acceso de
                      clientes y potenciales a la plataforma, prestarles el
                      servicio y facturar a los mismos.
                    </p>
                    <p>
                      Asimismo KM77 también trata los datos personales de los
                      usuarios de este Sitio Web para prestar servicios de
                      soporte y atenci&oacute;n al cliente y gestionar la
                      solicitud de y remisi&oacute;n de informes.
                    </p>
                    <p>Asimismo</p>
                    <li>
                      <b>
                        En base a la existencia de un inter&eacute;s
                        leg&iacute;timo por parte de KM77
                      </b>
                      <b>:</b>
                    </li>
                    <ol className="list-disc ml-4 md:ml-8">
                      <li>
                        Enviarte promociones y descuentos para futuras
                        reservas/compras.
                      </li>
                      <li>
                        Mantenerte informado acerca de los productos y servicios
                        de KM77, así como enviarte noticias, avisarte
                        de eventos y del resto de actividades que llevamos a
                        cabo en KM77, por medios convencionales y/o
                        electrónicos (correos electrónicos, SMS, etc.),
                        relacionadas con los productos o servicios que
                        comercializamos y que puedan ser de tu interés.
                      </li>
                      <li>
                        Llevar a cabo revisiones internas y en su caso,
                        contactar con el cliente en el supuesto de que se
                        detectaran o existieran fundadas sospechas en
                        relaci&oacute;n con un posible fraude o
                        suplantaci&oacute;n de identidad.
                      </li>
                      <li>
                        Llevar a cabo revisiones internas y en su caso,
                        contactar con el cliente en el supuesto de que se
                        detectaran o existieran fundadas sospechas en relación
                        con un posible fraude o suplantación de identidad.
                      </li>
                    </ol>
                    <p>
                      No obstante, si no deseas que se traten tus datos con
                      estas finalidades, puedes oponerte en cualquier momento
                      contactando con nosotros a trav&eacute;s de{" "}
                      <a className="text-blue-800" href="informes.segundamano@km77.com">
                        {" "}
                        informes.segundamano@km77.com
                      </a>{" "}
                      (correo de KM77), tal y como se indica en el apartado de
                      Gesti&oacute;n de Derechos de la presente Pol&iacute;tica
                      de Privacidad.
                    </p>
                    <li>
                      <b>
                        En base al consentimiento expl&iacute;cito, libre e
                        inequ&iacute;voco que, en su caso, nos otorgues en el
                        momento de recoger tus datos
                      </b>
                      <b>:</b>
                    </li>
                    <ol className="list-disc ml-4 md:ml-8">
                      <li>
                        Elaborar perfiles con fines estad&iacute;sticos y/o de
                        segmentaci&oacute;n para analizar el uso y las
                        transacciones que realizas de nuestro Sitio Web.
                      </li>
                      <li>
                        Realizar labores de segmentación y obtención de perfiles
                        según tus intereses y a través del análisis de la
                        utilización de los servicios ofrecidos, tales como el
                        historial de navegación o las compras realizadas a
                        través del Sitio Web, con la finalidad de adecuar las
                        ofertas y promociones a cada cliente, ofreciendo
                        servicios e información personalizada.
                      </li>
                    </ol>
                  </ul>
                  <li className="">
                    <b>
                      Cesi&oacute;n de datos y transferencias internacionales.
                    </b>
                  </li>
                  <p>
                    Todas las cesiones de datos personales que realizan KM77 
                    resultan necesarias para el cumplimiento de las
                    finalidades indicadas, como o se realizan para cumplir una
                    obligaci&oacute;n legal, tales como:
                  </p>
                  <ol className="list-disc ml-4 md:ml-8">
                    <li>
                      A las administraciones p&uacute;blicas y
                      administraci&oacute;n de justicia, y a los cuerpos y
                      fuerzas de seguridad en cumplimiento de las obligaciones
                      legales que nos son de aplicaci&oacute;n.
                    </li>
                    <li>
                      Entidades colaboradoras cuya intervenci&oacute;n es
                      necesaria para poder prestar el servicio al usuario, tales
                      como la Direcci&oacute;n General de Tr&aacute;fico en el
                      caso de que el usuario decida adquirir el Informe
                      detallado DGT (definido en los T&eacute;rminos y
                      Condiciones de Uso).
                    </li>
                    <li>
                      Empresas proveedoras de servicios de pago (entidades
                      bancarias, pasarelas de pago, etc.), en caso de que el
                      cliente realice alg&uacute;n pago a trav&eacute;s del
                      servicio ofrecido por una de estas empresas.
                    </li>
                    <li>
                      Empresas prestadoras de servicios inform&aacute;ticos,
                      herramientas o infraestructura inform&aacute;tica sobre la
                      que se asientan los servicios que KM77 prestan,
                      tales como proveedores de hosting, CRMs, empresas de
                      servicios de emailing, etc.
                    </li>
                  </ol>
                  <p>
                    En este sentido, te informamos de que cualquier
                    cesi&oacute;n que se produzca se realizar&aacute; teniendo
                    en cuenta todas las salvaguardas legales necesarias.
                    Asimismo, garantizamos que firmamos con todos nuestros
                    proveedores de servicios contratos espec&iacute;ficos
                    seg&uacute;n establece la normativa.
                  </p>
                  <p>
                    Del mismo modo, KM77 garantizan que en caso de
                    existir proveedores que se encuentren en pa&iacute;ses fuera
                    de la Uni&oacute;n Europea u otros no considerados adecuados
                    de conformidad con la normativa de protecci&oacute;n de
                    datos, se toman las precauciones necesarias que garantizan
                    que los datos pueden ser transferidos de manera segura, ya
                    sea porque el proveedor ofrece garant&iacute;as adecuadas, a
                    trav&eacute;s, entre otros, de la firma de Cl&aacute;usulas
                    Contractuales Tipo de la Comisi&oacute;n Europea, o bien se
                    cumple alguna de las excepciones recogidas en la normativa.
                  </p>
                  <li className="">
                    <b>Ejercicio de tus derechos.</b>
                  </li>
                  <p>
                    Te informamos de que podr&aacute;s ejercer los siguientes
                    derechos:
                  </p>
                  <ol className="list-disc ml-4 md:ml-8">
                    <li>
                      Derecho de acceso a sus datos personales para saber
                      cu&aacute;les est&aacute;n siendo objeto de tratamiento y
                      las operaciones de tratamiento llevadas a cabo con ellos.
                    </li>
                    <li>
                      Derecho de rectificaci&oacute;n de cualquier dato personal
                      inexacto.
                    </li>
                    <li>
                      Derecho de supresi&oacute;n de sus datos personales,
                      cuando esto sea posible.
                    </li>
                    <li>
                      Derecho a solicitar la limitaci&oacute;n del tratamiento
                      de sus datos personales cuando la exactitud, la legalidad
                      o la necesidad del tratamiento de los datos resulte
                      dudosa, en cuyo caso, podremos conservar los datos para el
                      ejercicio o la defensa de reclamaciones.
                    </li>
                    <li>
                      Derecho a oponerse a la toma de decisiones automatizadas.
                    </li>
                    <li>
                      Derecho de oposici&oacute;n al tratamiento de tus datos
                      personales cuando la base legal que nos habilite para su
                      tratamiento, conforme al apartado 4 anterior, sea el
                      inter&eacute;s leg&iacute;timo.
                    </li>
                    <li>
                      Derecho a la portabilidad de tus datos, cuando la base
                      legal que nos habilite para su tratamiento de las
                      indicadas en el apartado 4 anterior sea la existencia de
                      una relaci&oacute;n contractual o tu consentimiento.
                    </li>
                    <li>
                      Derecho a revocar el consentimiento otorgado a KM77 
                    </li>
                  </ol>
                  <p>
                    <b>
                      Podr&aacute;s ejercitar tus derechos en cualquier momento
                      y de forma gratuita de las siguientes formas:
                    </b>
                  </p>
                  <ol className="list-disc ml-4 md:ml-8">
                    <li>
                      Dirigiendo un correo electr&oacute;nico a la
                      direcci&oacute;n{" "}
                      <a className="text-blue-800" href="mailto:informes.segundamano@km77.com">
                        {" "}
                        informes.segundamano@km77.com
                      </a> indicando el derecho que
                      deseas ejercitar y tus datos identificativos.
                    </li>
                    <li>
                      Dirigiendo una solicitud escrita indicando el derecho que
                      deseas ejercitar y tus datos personales a la
                      direcci&oacute;n de KM77, Calle Golfo de Sal&oacute;nica,
                      49 1ºJ 28033 Madrid (Espa&ntilde;a).
                    </li>
                    <li>
                      Adem&aacute;s, cuando recibas cualquier
                      comunicaci&oacute;n nuestra, clicando en la secci&oacute;n
                      de baja que contendr&aacute; esa comunicaci&oacute;n,
                      podr&aacute;s darte de baja de todos env&iacute;os de
                      comunicaciones comerciales previamente aceptadas.
                    </li>
                  </ol>
                  <p>
                    Asimismo, te informamos de que tienes derecho a presentar
                    una reclamaci&oacute;n ante la Agencia Espa&ntilde;ola de
                    Protecci&oacute;n de Datos si consideras que hemos cometido
                    una infracci&oacute;n de la legislaci&oacute;n en materia de
                    protecci&oacute;n de datos respecto al tratamiento de tus
                    datos personales.
                  </p>
                  <p>
                    Adem&aacute;s, te informamos de que puedes apuntarte en la
                    Lista Robinson en{" "}
                    <a
                      className="text-blue-800"
                      href="http://www.listarobinson.es"
                    >
                      www.listarobinson.es
                    </a>
                    : el sistema de exclusi&oacute;n publicitaria gestionado la
                    Asociaci&oacute;n Espa&ntilde;ola de Econom&iacute;a Digital
                    (ADIGITAL), en el que puedes inscribirte con el objeto de
                    mostrar tu oposici&oacute;n a que se utilicen tus datos con
                    el fin de remitirte comunicaciones comerciales.
                  </p>
                  <li className="">
                    <b>Periodos de retenci&oacute;n y Cookies.</b>
                  </li>
                  <p>
                    S&oacute;lo mantendremos tus datos durante el tiempo que sea
                    necesario para ofrecerte estos servicios. Cualquiera de los
                    datos que nos facilites a trav&eacute;s de los canales
                    ser&aacute;n bloqueados siempre y cuando dejen de ser
                    necesarios para gestionar el servicio y s&oacute;lo
                    estar&aacute;n disponibles en caso de que exista una
                    obligaci&oacute;n legal o sean necesarios para ejercicio de
                    reclamaciones.
                  </p>
                  <p>
                    En relaci&oacute;n con tus datos personales obtenidos a
                    trav&eacute;s de los canales, KM77 utilizan{" "}
                    <em>cookies </em>seg&uacute;n se expone en la
                    Pol&iacute;tica de Cookies que podr&aacute;s consultar{" "}
                    <Link
                      className="text-blue-800"
                      to="/cookies"
                      //onClick={() => logEvent("Política de cookies")}
                    >
                      aquí
                    </Link>{" "}
                  </p>
                  <li className="">
                    <b>Seguridad y confidencialidad.</b>
                  </li>
                  <p>
                    Estamos comprometidos con la seguridad de tu
                    informaci&oacute;n. Con el objeto de prevenir accesos no
                    autorizados o la revelaci&oacute;n no autorizada de datos
                    personales, hemos tomado las medidas adecuadas tanto
                    t&eacute;cnicas como f&iacute;sicas, as&iacute; como los
                    procesos de gesti&oacute;n para salvaguardar y asegurar la
                    informaci&oacute;n que recabamos de ti.
                  </p>
                  <li className="">
                    <b>Menores.</b>
                  </li>
                  <p>
                    Los menores de 18 a&ntilde;os no podr&aacute;n usar los
                    servicios disponibles a trav&eacute;s del Sitio Web. En este
                    sentido, y en la medida en que KM77 no tiene
                    capacidad para controlar si los usuarios son o no menores de
                    edad, deber&aacute;n ser los padres y tutores quienes
                    habiliten los mecanismos necesarios para evitar que los
                    menores a su cargo accedan al Sitio Web y/o faciliten datos
                    de car&aacute;cter personal sin su supervisi&oacute;n, no
                    admitiendo KM77 responsabilidad alguna al
                    respecto.
                  </p>
                  <li className="">
                    <b>
                      Actualizaci&oacute;n de la Pol&iacute;tica de Privacidad.
                    </b>
                  </li>
                  <p>
                    Hacemos nuestros mejores esfuerzos para mantener nuestra
                    Pol&iacute;tica de Privacidad totalmente actualizada
                    mensualmente. Si hacemos cambios, estos ser&aacute;n
                    claramente identificables de forma clara y
                    espec&iacute;fica, conforme se permite en la relaci&oacute;n
                    que hemos establecido contigo (por ejemplo: te podemos
                    comunicar los cambios por email).
                  </p>
                  <p>Fecha de &uacute;ltima actualizaci&oacute;n: 18/08/2021</p>
                </ol>
                <p className="">
                  <a
                    className="flex text-blue-800 justify-start items-center"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      history.go(-1);
                    }}
                  >
                    <svg
                      class="fill-current text-blue-800 p-0 m-0 w-8 h-8"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z" />
                    </svg>
                    Volver
                  </a>
                </p>{" "}
              </div>
            </div>
          </div>
        )}
      </Amplitude>
    </div>
  );
}
