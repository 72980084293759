import React from "react";
import userContext from "../userContext";
import { useHistory, Link } from "react-router-dom";
import madivalogo from "../images/madivalogo.png";
import km77logo from "../images/km77-logo.png";
import { Amplitude } from "@amplitude/react-amplitude";

export default function CondicionesContratacion() {
  const session = React.useContext(userContext);

  let history = useHistory();
  React.useEffect(() => {}, [session]);

  return (
    <div className="m-0 p-0">
      <Amplitude>
        {({ logEvent }) => (
          <div className="m-4">
            <Link to="/">
              <img
                src={madivalogo}
                alt="Madiva"
                className="box-border p-3 w-20 md:w-36 float-right"
              />
              <img
                src={km77logo}
                alt="Km77"
                className="box-border p-3 w-20 md:w-36 float-right"
              />
            </Link>
            <div className="font-mulish leading-7">
              <div className="mx-4">
                <h1 className="my-4 text-2xl text-blue-800 font-bold">
                  Condiciones generales de contratación
                </h1>
                <div className="mb-2">
                  El presente documento tiene por finalidad regular las
                  condiciones generales de contratación (en adelante, las
                  &ldquo;<b>Condiciones Generales de Contratación</b>&rdquo;)
                  aplicables a cualquier persona (en adelante, el{" "}
                  <b>&ldquo;Usuario&rdquo;</b>) que adquiera alguno de los
                  productos y servicios disponibles en la URL […] y cualesquiera
                  de sus subdominios (en adelante, el{" "}
                  <b>&ldquo;Sitio Web&rdquo;</b>).
                </div>
                <div className="mb-2">
                  El responsable de la prestación del servicio y de la
                  facilitación de los productos ofrecidos a través de este Sitio
                  Web es RUEDAS DE PRENSA, S.L. (&ldquo;<b>KM77</b>&rdquo;),
                  cuyos datos identificativos son los siguientes:
                </div>
                <div>
                  <ul class="list-disc ml-6 mb-2">
                    <li>NIF: B82262254.</li>
                    <li>
                      Domicilio: Calle Golfo de Salónica, 49 1ºJ, con
                      C&oacute;digo Postal 28033, Madrid (Espa&ntilde;a).
                    </li>
                    <li>Tel&eacute;fono de contacto: 915130495</li>
                    <li>
                      Direcci&oacute;n de correo electr&oacute;nico de contacto:{" "}
                      <a className="text-blue-800" href="mailto: dpo@km77.com">
                        dpo@km77.com
                      </a>
                    </li>
                    <li>
                      Datos de inscripci&oacute;n en el Registro Mercantil: Hoja
                      M-228883, Folio 44, Tomo 13.967
                    </li>
                  </ul>
                </div>               
                <div className="mb-2">
                  Se informa al Usuario de que su relación con KM77 se regirá,
                  además de por las presentes Condiciones Generales de
                  Contratación, por los{" "}
                  <Link
                    className="text-blue-800"
                    to="/condicionesuso"                    
                  >
                    Términos y Condidiones
                  </Link>
                  , la{" "}
                  <Link
                    className="text-blue-800"
                    to="/privacidad"                    
                  >
                    Política de privacidad
                  </Link>{" "}
                  y la{" "}
                  <Link
                    className="text-blue-800"
                    to="/cookies"                    
                  >
                    Política de cookies
                  </Link>{" "}
                  de KM77, así como por cualquier otro documento contractual que
                  ambas partes suscriban en relación con el servicio prestado
                  por KM77 (en adelante, el <b>&ldquo;Servicio&rdquo;</b>).
                </div>
                <ol className="list-decimal ml-4 md:ml-8">
                  <li className="font-bold">
                    <b>DEFINICIONES</b>
                  </li>
                  <ol className="list-decimal ml-4 md:ml-8">
                    <li className="">
                      <b>&ldquo;Herramienta de valoración&rdquo;</b>: se
                      entenderá por Herramienta de valoración la herramienta
                      ofrecida por KM77 a los Usuarios para la valoración de
                      vehículos de segundo mano con base en la comparación de
                      datos de otros vehículos comparables en oferta. La
                      Herramienta de valoración se entiende, a los efectos de
                      estas Condiciones Generales de Contratación, como un
                      elemento del Sitio Web, por lo que las referencias
                      realizadas al Sitio Web se considerarán hechas también a
                      la Herramienta de valoración.
                    </li>
                    <li className="">
                      <b>&ldquo;Informe&rdquo;</b>: el término Informe podrá
                      referirse a cualquiera de los distintos tipos de Informes
                      que los Usuarios pueden adquirir en el Sitio Web.
                    </li>
                    <li className="">
                      <b>&ldquo;Informe KM77&rdquo;</b>: se entenderá por
                      Informe KM77 el Informe básico que el Usuario podrá
                      adquirir en el Sitio Web y que le proporcionará una
                      valoración del vehículo y cierta información adicional
                      gracias a la utilización de la Herramienta de valoración,
                      en los términos recogidos en la cláusula 2.
                    </li>
                    <li className="">
                      <b>&ldquo;Informe detallado DGT&rdquo;</b>: se entenderá
                      por Informe detallado DGT el Informe que el Usuario podrá
                      obtener de forma adicional al Informe KM77, mediante el
                      pago de un suplemento, y que le proporcionará información
                      sobre algunos o todos de los siguientes elementos, , de
                      conformidad con los términos recogidos en la cláusula 2:
                      el historial de ITV, el municipio en el que se encuentra
                      domiciliado el vehículo y la puntuación EuroNCAP, etc.
                    </li>
                    <li className="">
                      <b>&ldquo;Método de Pago&rdquo;</b>: será el método
                      disponible en el Sitio Web para realizar pagos o compras
                      de productos o servicios allí disponibles.
                    </li>
                  </ol>
                  <li className="font-bold">
                    <b>SERVICIOS</b>
                  </li>
                  <ol className="list-decimal ml-4 md:ml-8">
                    <li className="">
                      KM77 ofrece, a través del Sitio Web y de la utilización de
                      la Herramienta de valoración, un servicio de valoración de
                      vehículos basado en el análisis comparativo de datos de
                      otros vehículos en oferta. A través del Sitio Web, los
                      Usuarios pueden utilizar la Herramienta de valoración y
                      adquirir Informes sobre distintas características técnicas
                      y administrativas de determinados vehículos (en adelante,
                      el <b>&ldquo;Servicio</b>&ldquo; o los{" "}
                      <b>&ldquo;Servicios</b>&rdquo;).
                    </li>
                    <li className="">
                      El Servicio permite a los Usuarios acceder a los
                      siguientes tipos de informes y/o a cualesquiera otros que
                      en cualquier momento se encuentren disponibles a través
                      del Sitio Web:
                      <ol className="list-disc ml-4 md:ml-8">
                        <li>
                          Informe KM77: el Informe KM77 contiene información
                          relativa, entre otros, al precio estimado del
                          vehículo, la depreciación, el importe que abonaría el
                          seguro en caso de siniestro total y los precios de
                          otros posibles vehículos que podrían resultar de
                          interés para el Usuario. Todas las cifras incluidas en
                          el Informe KM77 son meramente orientativas. Para su
                          cálculo, la Herramienta de valoración de KM77 compara
                          datos disponibles sobre vehículos con características
                          similares en oferta. La utilización de la Herramienta
                          de valoración requerirá al Usuario introducir
                          información sobre aspectos como la marca y el modelo
                          del vehículo, el año de matriculación, el tipo de
                          combustible, la potencia y el kilometraje. La emisión
                          del Informe KM77 se somete a la existencia de
                          suficientes datos de vehículos comparables en oferta
                          que permitan a la Herramienta de valoración realizar
                          una aproximación al valor del vehículo.
                        </li>
                        <li>
                          Informe detallado DGT: el Informe detallado DGT se
                          divide en diversas secciones. No obstante, una o más
                          secciones pueden no aparecer en un Informe detallado
                          DGT, lo que sucederá en aquellos casos en los que la
                          DGT no disponga de anotaciones relacionadas. Por lo
                          general, las secciones en las que se divide el Informe
                          detallado DGT son: datos del titular, identificación
                          del vehículo, datos sobre el seguro obligatorio, datos
                          sobre la inspección técnica de vehículos, historial de
                          bajas, historial de lecturas del cuentakilómetros,
                          indicador de la existencia de una incidencia
                          denegatoria, cargas o gravámenes, información técnica,
                          historial de titulares, información medioambiental e
                          información sobre la seguridad del vehículo. El
                          Usuario puede obtener más información sobre el Informe
                          detallado DGT{" "}
                          <a
                            className="text-blue-800"
                            href="https://sede.dgt.gob.es/es/vehiculos/informe-de-vehiculo/"
                          >
                            aquí
                          </a>
                          .
                        </li>
                      </ol>
                    </li>
                    <li className="">
                      Los Usuarios pueden acceder a una mayor información sobre
                      las características de cada uno de los Informes en este{" "}
                      <a
                        className="text-blue-800"
                        href="https://sede.dgt.gob.es/es/vehiculos/informe-de-vehiculo/"
                      >
                        enlace
                      </a>
                      .
                    </li>
                    <li className="">
                      No obstante lo dispuesto en esta cláusula, KM77 no
                      garantiza que los Informes adquiridos siempre contengan
                      toda la información recogida en los párrafos anteriores.
                      Asimismo, KM77 se reserva el derecho a modificar, en
                      cualquier momento, el número y la tipología de Informes
                      disponibles para su adquisición en el Sitio Web.
                    </li>
                    <li className="">
                      Se informa al Usuario de que la DGT pone a disposición del
                      público ciertos informes que pueden ser obtenidos
                      directamente por el Usuario a través del Organismo
                      Autónomo Jefatura Central de Tráfico.
                    </li>
                    <li className="">
                      Una vez adquirido uno o más Informes, el Usuario los
                      recibirá de forma automática en un archivo en formato PDF
                      en la dirección de correo electrónico que haya designado
                      al efecto. Habitualmente, la recepción de los Informes en
                      la cuenta de correo electrónico designada se produce a los
                      pocos minutos de su adquisición, si bien pueden
                      registrarse retrasos por causas ajenas a la voluntad de
                      KM77, de los que KM77 no responderá.
                    </li>
                    <li className="">
                      Dado que los Informes se suministran al Usuario en soporte
                      digital,{" "}
                      <ins>
                        <b>
                          el Usuario perderá su derecho de desistimiento en el
                          momento en el que le sea remitido el correo
                          electrónico con los Informes
                        </b>
                      </ins>
                    </li>
                  </ol>
                  <li className="font-bold">
                    <b>MENORES DE EDAD</b>
                  </li>
                  <ol className="list-decimal ml-4 md:ml-8">
                    <li className="">
                      El acceso y el uso del Sitio Web y, en consecuencia, la
                      adquisición de Informes solo está permitido para mayores
                      de 18 años. Al aceptar estas Condiciones Generales de
                      Contratación el Usuario declara y garantiza ser mayor de
                      18 años, siendo el único responsable de la veracidad de
                      tal declaración.
                    </li>
                  </ol>
                  <li className="font-bold">
                    <b>PRECIOS Y PAGOS</b>
                  </li>
                  <ol className="list-decimal ml-4 md:ml-8">
                    <li className="">
                      La adquisición de algunos de los Informes disponibles en
                      el Sitio Web puede estar sujeta al pago de una cantidad
                      que variará en función del Informe que el Usuario desee
                      adquirir.
                    </li>
                    <li className="">
                      La adquisición y el pago de los Informes KM77 solo será
                      posible en aquellos casos en los que la Herramienta de
                      valoración pueda efectuar una valoración del vehículo por
                      existir suficientes datos comparables en otros vehículos
                      oferta. En caso contrario, se informará al Usuario de que
                      no es posible emitir ningún Informe sobre el vehículo.
                    </li>
                    <li className="">
                      Todos los precios mostrados en el Sitio Web aparecen
                      expresados en EUROS (€).
                    </li>
                    <li className="">
                      El Usuario abonará el precio a través de los Métodos de
                      Pago disponibles en el Sitio Web.
                    </li>
                    <li className="">
                      Todos los pagos se procesan a través de un proveedor de
                      servicios externo. KM77 no garantiza ni, en ningún caso,
                      se hace responsable de la disponibilidad o accesibilidad a
                      la plataforma de pago, de la interrupción en su
                      funcionamiento, ni de los fallos informáticos, errores o
                      retrasos no imputables al Sitio Web o a KM77.
                    </li>
                    <li className="">
                      KM77 no será responsable de los cargos adicionales que
                      puedan ser aplicados por el proveedor del Método de Pago
                      del Usuario en cualquier concepto, incluyendo, pero no
                      limitándose, al coste por el cambio de divisas o a la
                      aplicación de impuestos y tasas.
                    </li>
                    <li className="">
                      En caso de solicitar la emisión de factura, el Usuario
                      acepta que esta le sea remitida en formato de factura
                      electrónica. No obstante lo anterior, el Usuario podrá
                      revocar su consentimiento a la recepción de la factura
                      electrónica y requerir su envío en papel mediante
                      solicitud enviada a la dirección de correo electrónico:{" "}
                      <a className="text-blue-800" href="mailto: dpo@km77.com">
                        dpo@km77.com
                      </a>
                    </li>
                  </ol>
                  <li className="font-bold">
                    <b>PROPIEDAD INTELECTUAL</b>
                  </li>
                  <ol className="list-decimal ml-4 md:ml-8">
                    <li className="">Generalidades</li>
                    <ol className="list-disc ml-4 md:ml-8">
                      <li>
                        El Usuario no ostentará, en virtud de estas Condiciones
                        Generales de Contratación, ningún derecho sobre los
                        productos, sistemas, Servicios, marcas y/o nombres
                        comerciales o cualesquiera otros elementos cuya
                        propiedad intelectual corresponda a KM77 o a cualquier
                        otro tercero, más allá de los expresamente indicados en
                        esta cláusula.
                      </li>
                    </ol>
                    <li className="">
                      Propiedad Intelectual sobre los Informes
                    </li>
                    <ol className="list-disc ml-4 md:ml-8">
                      <li>
                        El Usuario reconoce que sobre los Informes existen
                        derechos de propiedad intelectual titularidad de KM77 o
                        de terceros y que la adquisición de los Informes en
                        ningún caso supondrá una cesión de tales derechos de
                        propiedad intelectual.
                      </li>
                      <li>
                        El Usuario podrá utilizar los Informes con una finalidad
                        estrictamente doméstica y no podrá revender a terceros
                        ni de cualquier forma utilizar los Informes con fines
                        comerciales.
                      </li>
                      <li>
                        El Usuario se abstendrá de reproducir, distribuir,
                        comunicar públicamente, transformar o de cualquier otra
                        forma explotar los Informes a los que tenga acceso a
                        través del Sitio Web.
                      </li>
                      <li>
                        El Usuario se abstendrá de extraer y reutilizar la
                        información contenida en los Informes, ya sea de forma
                        total o de forma parcial, automática o manual, por lo
                        que no podrá, entre otros, aplicar técnicas de
                        “screen-scraping” similares.
                      </li>
                    </ol>
                  </ol>
                  <li className="font-bold">
                    <b>TRATAMIENTO DE DATOS DE CARÁCTER PERSONAL</b>
                  </li>
                  <ol className="list-decimal ml-4 md:ml-8">
                    <li className="">
                      Para la elaboración y remisión de los Informes, KM77
                      precisará tratar ciertos datos del Usuario.
                    </li>
                    <li className="">
                      Es muy importante para KM77 que el Usuario tenga clara la
                      política de KM77 en lo relativo a la protección de datos
                      de carácter personal. Por ello, KM77 toma las máximas
                      precauciones al recopilar la información personal y no
                      personal del Usuario.
                    </li>
                    <li className="">
                      Para una clara comprensión de las políticas aplicadas por
                      KM77 en el manejo de información personal y privada, KM77
                      recomienda que el Usuario visite la{" "}
                      <Link
                        className="text-blue-800"
                        to="/privacidad"
                        //onClick={() => logEvent("Política de privacidad")}
                      >
                        Política de privacidad
                      </Link>{" "}
                      y la{" "}
                      <Link
                        className="text-blue-800"
                        to="/cookies"
                        //onClick={() => logEvent("Política de cookies")}
                      >
                        Política de cookies
                      </Link>{" "}
                      de KM77.
                    </li>
                  </ol>
                  <li className="font-bold">
                    <b>
                      MANIFESTACIONES, GARANTÍAS Y EXCLUSIÓN DE RESPONSABILDAD
                    </b>
                  </li>
                  <ol className="list-decimal ml-4 md:ml-8">
                    <li className="">
                      Los Informes y otros productos y/o servicios
                      comercializados a través del Sitio Web pueden contener
                      inexactitudes técnicas y errores tipográficos. Ni KM77 ni
                      sus colaboradores asumirán responsabilidad alguna por
                      dichas inexactitudes, errores u omisiones.
                    </li>
                    <li className="">
                      La Herramienta de valoración utiliza técnicas basadas en
                      la comparación de datos de otros vehículos en oferta, por
                      lo que para un vehículo en concreto puede no ser capaz de
                      ofrecer una valoración, en caso de que la información
                      disponible sobre vehículos similares sea limitada. KM77 no
                      se hace responsable en caso de que la Herramienta de
                      valoración no pueda ofrecer al Usuario una valoración del
                      vehículo.
                    </li>
                    <li className="">
                      KM77 no será responsable frente al Usuario ni frente a
                      terceros, ya sea de forma directa o indirecta, incluyendo,
                      pero no limitándose a daños indirectos y/o accidentales,
                      cualquiera que sea su naturaleza o concepto, con motivo de
                      las presentes Condiciones Generales de Contratación y/o
                      cualquier producto o servicio ofrecido por KM77.
                    </li>
                    <li className="">
                      El Usuario es el único responsable de los datos
                      introducidos en el Sitio Web y del uso de los Informes
                      obtenidos a partir de dichos datos, comprometiéndose a
                      mantener indemne a KM77 y a sus colaboradores por
                      cualquier reclamación que éstos pudieran recibir como
                      consecuencia de las actuaciones del Usuario.
                    </li>
                    <li className="">
                      La responsabilidad total de KM77, en caso de que esta sea
                      reconocida por un juzgado o tribunal, frente al Usuario
                      por cualquier causa no excluida por la estipulación
                      anterior no excederá la suma pagada por el Usuario a KM77
                      por la prestación de los Servicios.
                    </li>
                    <li className="">
                      Cuando la legislación aplicable no permita una exención de
                      responsabilidad en los términos recogidos en esta
                      cláusula, la responsabilidad de KM77 quedará limitada todo
                      lo que resulte posible de acuerdo con la ley.
                    </li>
                  </ol>
                  <li className="font-bold">
                    <b>SUGERENCIAS Y RECLAMACIONES</b>
                  </li>
                  <ol className="list-decimal ml-4 md:ml-8">
                    <li className="">
                      El Usuario que desee trasladar una reclamación a KM77
                      podrá hacerlo enviando su queja o consulta a la dirección
                      de correo electrónico{" "}
                      <a className="text-blue-800" href="mailto: informes.segundamano@km77.com">
                       informes.segundamano@km77.com
                      </a>
                    </li>
                    <li className="">
                      El Usuario podrá dirigirse a esa misma dirección para
                      plantear cualquier consulta, sugerencia o recomendación.
                    </li>
                  </ol>
                  <li className="font-bold">
                    <b>MISCELÁNEA</b>
                  </li>
                  <ol className="list-decimal ml-4 md:ml-8">
                    <li className="">
                      KM77 perseguirá el incumplimiento de las presentes
                      Condiciones Generales de Contratación, así como cualquier
                      utilización indebida de su Sitio Web y/o de su Servicio
                      ejerciendo todas las acciones civiles y penales que le
                      puedan corresponder en derecho.
                    </li>
                    <li className="">
                      El no ejercicio o ejecución por parte de KM77 de cualquier
                      derecho o disposición contenida en estas Condiciones
                      Generales de Contratación no constituirá una renuncia al
                      mismo ni, en su caso, exonerará al Usuario del
                      cumplimiento de sus obligaciones, salvo reconocimiento y
                      acuerdo expreso y por escrito de KM77.
                    </li>
                    <li className="">
                      KM77 podrá modificar en cualquier momento las presentes
                      Condiciones Generales de Contratación. La versión de las
                      Condiciones Generales de Contratación vigente será aquella
                      que, en cada momento, se encuentre publicada.
                    </li>
                  </ol>
                  <li className="font-bold">
                    <b>LEY APLICABLE Y JURISDICCIÓN</b>
                  </li>
                  <ol className="list-decimal ml-4 md:ml-8">
                    <li className="">
                      Las presentes Condiciones Generales de Contratación se
                      rigen por la legislación española.
                    </li>
                    <li className="">
                      En los casos en los que la legislación lo permita, las
                      partes, renunciando a su propio fuero, acuerdan someter
                      cualquier conflicto, controversia y/o reclamación
                      relacionada con estas Condiciones Generales de
                      Contratación a los Jueces y Tribunales de Madrid
                      (Capital).
                    </li>
                    <li className="">
                      Asimismo, los Usuarios tendrán en todo momento la
                      posibilidad de acudir a la plataforma de resolución de
                      litigios en línea de la Comisión Europea a la que se puede
                      acceder a través de este{" "}
                      <a
                        className="text-blue-800"
                        href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=ES"
                      >
                        enlace.
                      </a>
                    </li>
                  </ol>
                </ol>
                Fecha de última actualización: 18/08/2021
              </div>
              <p className="">
                <a
                  className="flex text-blue-800 justify-start items-center"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    history.go(-1);
                  }}
                >
                  <svg
                    class="fill-current text-blue-800 p-0 m-0 w-8 h-8"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z" />
                  </svg>
                  Volver
                </a>
              </p>
            </div>
          </div>
        )}
      </Amplitude>
    </div>
  );
}
