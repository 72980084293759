import React from "react";
import { Link } from "react-router-dom";
import { Amplitude } from "@amplitude/react-amplitude";
export default function Footer() {
  return (
    <div className="w-full mx-auto container footer text-xs text-center mt-10 mb-6">
      <Amplitude>
        {({ logEvent }) => (
          <div className="flex flex-wrap justify-center font-mulish">
            <div className="px-2">
              <Link to="/avisolegal" 
                //onClick={() => logEvent("Aviso Legal")}
              >
                Aviso legal
              </Link>
            </div>
            <div className="px-2">|</div>
            <div className="px-2">
              <Link
                to="/condicionesuso"
                //onClick={() => logEvent("Términos y Condidiones")}
              >
                Términos y Condidiones
              </Link>
            </div>
            <div className="px-2">|</div>
            <div className="px-2">
              <Link
                to="/condicionescontratacion"
                //onClick={() =>logEvent("Condiciones Generales de Contratación")}
              >
                Condiciones Generales de Contratación
              </Link>
            </div>
            <div className="px-2">|</div>
            <div className="px-2">
              <Link
                to="/cookies"
                //onClick={() => logEvent("Política de cookies")}
              >
                Política de cookies
              </Link>
            </div>
            <div className="px-2">|</div>
            <div className="px-2">
              <Link
                to="/privacidad"
                //onClick={() => logEvent("Política de privacidad")}
              >
                Política de privacidad
              </Link>
            </div>
            <div className="px-2">|</div>
            <div className="px-2">
              <Link to="/faqs" 
                //onClick={() => logEvent("F.A.Q.S.")}
              >
                F.A.Q.S.
              </Link>
            </div>
            <div className="px-2">|</div>
            <div className="px-2">
              <Link to="/contacto" 
                //onClick={() => logEvent("Contacto")}
              >
                Contacto
              </Link>
            </div>
          </div>
        )}
      </Amplitude>
    </div>
  );
}
