import React from "react";
import userContext from "../userContext";
import { useHistory, Link } from "react-router-dom";
import madivalogo from "../images/madivalogo.png";
import km77logo from "../images/km77-logo.png";
import { Amplitude } from "@amplitude/react-amplitude";

export default function Contacto() {
  const session = React.useContext(userContext);

  let history = useHistory();
  React.useEffect(() => {}, [session]);

  return (
    <div className="m-0 p-0">
      <Amplitude>
        {({ logEvent }) => (
          <div className="m-4">
            <Link to="/">
              <img
                src={madivalogo}
                alt="Madiva"
                className="box-border p-3 w-20 md:w-36 float-right"
              />
              <img
                src={km77logo}
                alt="Km77"
                className="box-border p-3 w-20 md:w-36 float-right"
              />
            </Link>
            <div className="font-mulish leading-7">
              <div className="mx-4">
                <h1 className="my-4 text-2xl text-blue-800 font-bold">
                  T&eacute;rminos y condiciones de uso
                </h1>
                <div className="mb-2">
                  El presente documento tiene por finalidad regular los términos
                  y condiciones de uso del portal (en adelante, los “Términos y
                  Condiciones”) ubicado en la URL […] y cualesquiera de sus
                  subdominios (en adelante, conjuntamente, el “Sitio Web”) y son
                  aplicables a cualquier persona que acceda o utilice el Sitio
                  Web (en adelante, el “Usuario”).
                </div>
                <div className="mb-2">
                  El responsable de la prestación del servicio ofrecido a través
                  de este Sitio Web es RUEDAS DE PRENSA, S.L. (“KM77”), cuyos
                  datos identificativos son los siguientes:
                </div>
                <div className="mb-2">
                  <ul class="list-disc ml-4 md:ml-8">
                    <li>NIF: B82262254.</li>
                    <li>
                      Domicilio: Calle Golfo de Sal&oacute;nica, 49 1ºJ 28033
                      Madrid (Espa&ntilde;a).
                    </li>
                    <li>Tel&eacute;fono de contacto: 917240570</li>
                    <li>
                      Direcci&oacute;n de correo electr&oacute;nico de contacto:{" "}
                      <a className="text-blue-800" href="mailto:dpo@km77.com">
                        {" "}
                        dpo@km77.com
                      </a>
                    </li>
                    <li>
                      Datos de inscripci&oacute;n en el Registro Mercantil:
                      Registro Mercantil de Madrid, Hoja M-228883, Folio 44,
                      Tomo 13.967.
                    </li>
                  </ul>
                </div>                
                <div className="mb-2">
                  Estos Términos y Condiciones son vinculantes para los Usuarios
                  sólo por el mero hecho de acceder y/o utilizar el Sitio Web y
                  se aplican desde el primer momento. Dicho acceso y/o
                  utilización tendrá la consideración de válida y lícita
                  prestación del consentimiento por el que el Usuario acepta los
                  Términos y Condiciones que rigen su relación con KM77. El
                  Usuario debe leer estos Términos y Condiciones atentamente y,
                  en caso de no estar de acuerdo con los mismos, debe dejar de
                  utilizar el Sitio Web.
                </div>
                <div className="mb-2">
                  Además de los presentes Términos y Condiciones, la relación
                  del Usuario con KM77 se regirá, entre otros, por la{" "}
                  <Link
                    className="text-blue-800"
                    to="/privacidad"
                    onClick={() => logEvent("Política de privacidad")}
                  >
                    Política de privacidad
                  </Link>{" "}
                  y la{" "}
                  <Link
                    className="text-blue-800"
                    to="/cookies"
                    onClick={() => logEvent("Política de cookies")}
                  >
                    Política de cookies
                  </Link>{" "}
                  de KM77, así como por cualquier documento contractual que
                  ambas partes suscriban en relación con el servicio prestado
                  por KM77 en este Sitio Web. En particular, en caso de que el
                  Usuario adquiera alguno de los productos y/o servicios que se
                  comercializan a través del Sitio Web, resultarán de aplicación
                  las{" "}
                  <Link
                    className="text-blue-800"
                    to="/condicionescontratacion"
                    onClick={() =>
                      logEvent("Condiciones Generales de Contratación")
                    }
                  >
                    Condiciones Generales de Contratación
                  </Link>
                </div>
                <ol className="list-decimal ml-4 md:ml-8 mb-2">
                  <li className="">
                    <b>DEFINICIONES</b>
                    <ol className="list-decimal ml-4 md:ml-8 mb-2">
                      <li className="">
                        <b>&ldquo;Herramienta de valoración&rdquo; </b>se
                        entenderá por Herramienta de valoración la herramienta
                        ofrecida por KM77 a los Usuarios para la valoración de
                        vehículos de segundo mano con base en la comparación de
                        datos de otros vehículos comparables en oferta. La
                        Herramienta de valoración se entiende, a los efectos de
                        estos Términos y Condiciones, como un elemento del Sitio
                        Web, por lo que las referencias realizadas al Sitio Web
                        se considerarán hechas también a la Herramienta de
                        valoración.{" "}
                      </li>
                      <li className="">
                        <b>&ldquo;Informe&rdquo;</b>: el término Informe podrá
                        referirse a cualquiera de los distintos tipos de
                        Informes que los Usuarios pueden adquirir en el Sitio
                        Web.
                      </li>
                    </ol>
                  </li>
                  <li className="">
                    <b>SERVICIOS OFERTADOS EN EL SITIO WEB</b>
                    <ol className="list-decimal ml-4 md:ml-8 mb-2">
                      <li className="">
                        KM77 ofrece, a través del Sitio Web y de la utilización
                        de la Herramienta de valoración, un servicio de
                        valoración de vehículos basado en el análisis
                        comparativo de datos de otros vehículos en oferta. A
                        través del Sitio Web, los Usuarios pueden utilizar la
                        Herramienta de valoración y adquirir Informes sobre
                        distintas características técnicas y administrativas de
                        determinados vehículos (en adelante, el &ldquo;
                        <b>Servicio</b>&rdquo; o los &ldquo;
                        <b>Servicios</b>&rdquo;).
                      </li>
                      <li className="">
                        KM77 se reserva el derecho a realizar, en cualquier
                        momento, cambios en los Servicios ofertados a través del
                        Sitio Web.
                      </li>
                      <li className="">
                        La adquisición de Informes se regula por las{" "}
                        <Link
                          className="text-blue-800"
                          to="/condicionescontratacion"
                          onClick={() =>
                            logEvent("Condiciones Generales de Contratación")
                          }
                        >
                          Condiciones Generales de Contratación
                        </Link>
                        .
                      </li>
                    </ol>
                  </li>
                  <li className="">
                    <b>MENORES DE EDAD</b>
                    <ol className="list-decimal ml-4 md:ml-8 mb-2">
                      <li className="">
                        El acceso y el uso del Sitio Web solo est&aacute;
                        permitido para mayores de 18 a&ntilde;os. Al aceptar
                        estos T&eacute;rminos y Condiciones, el Usuario declara
                        y garantiza ser mayor de 18 a&ntilde;os, siendo el
                        &uacute;nico responsable de la veracidad de tal
                        declaraci&oacute;n.
                      </li>
                    </ol>
                  </li>
                  <li className="">
                    <b>CONDICIONES DE ACCESO</b>
                    <ol className="list-decimal ml-4 md:ml-8 mb-2">
                      <li className="">
                        El acceso al Sitio Web, la utilizaci&oacute;n de la
                        Herramienta de valoraci&oacute;n y la adquisici&oacute;n
                        de los distintos Informes no requieren el registro ni la
                        creaci&oacute;n de una cuenta de usuario.
                      </li>
                      <li className="">
                        El Usuario manifiesta y garantiza que, cuando para la
                        utilización de ciertas funcionalidades del Sitio Web o
                        para la adquisición de Informes se requiera que aporte
                        información, aportará únicamente información verídica y
                        que utilizará su nombre o el nombre legal de la entidad
                        a la que represente, no pudiendo utilizar identidades
                        falsas ni identidades de otras personas o entidades sin
                        disponer de la autorización necesaria para ello.
                      </li>
                      <li className="">
                        El Usuario se compromete a mantener a KM77 indemne
                        frente a terceros en relación con cualquier reclamación
                        dimanante de manifestaciones falsas, incluyendo, pero no
                        limitándose a la identidad y a la edad del Usuario.
                      </li>
                    </ol>
                  </li>
                  <li className="">
                    <b>ESPECIFICACIONES T&Eacute;CNICAS DEL SITIO WEB </b>
                    <ol className="list-decimal ml-4 md:ml-8 mb-2">
                      <li className="">
                        El Sitio Web está concebido y preparado para funcionar
                        en un sistema informático que cumpla con unas
                        determinadas especificaciones técnicas. KM77 no se hace
                        responsable por posibles problemas de compatibilidad
                        entre el Sitio Web y los sistemas y entornos del
                        Usuario.
                      </li>
                      <li className="">
                        KM77 se reserva el derecho de hacer mejoras,
                        sustituciones o modificaciones de cualquier parte del
                        Sitio Web.
                      </li>
                    </ol>
                  </li>
                  <li className="">
                    <b>PROPIEDAD INTELECTUAL</b>
                    <ol className="list-decimal ml-4 md:ml-8 mb-2">
                      <li className="">
                        {" "}
                        Generalidades
                        <ol className="list-disc ml-4 md:ml-8 mb-2">
                          <li>
                            El Usuario no ostentar&aacute;, en virtud de estos
                            T&eacute;rminos y Condiciones, ning&uacute;n derecho
                            sobre los productos, sistemas, Servicios, marcas y/o
                            nombres comerciales o cualesquiera otros elementos
                            cuya propiedad intelectual corresponda a KM77 o a
                            cualquier otro tercero, m&aacute;s all&aacute; de
                            los expresamente indicados en esta cl&aacute;usula.
                          </li>
                        </ol>
                      </li>
                      <li className="">
                        Propiedad Intelectual sobre el Sitio Web y la
                        Herramienta de valoración
                        <ol className="list-disc ml-4 md:ml-8 mb-2">
                          <li>
                            Sobre el Sitio Web y la Herramienta de valoración,
                            así como sobre todos los elementos contenidos en
                            ellos (incluyendo las imágenes, el software, el
                            texto, las marcas o logotipos, las combinaciones de
                            colores, la estructura y diseño del Sitio Web y de
                            la Herramienta de valoración) existen derechos de
                            propiedad intelectual e industrial titularidad de
                            KM77 o de terceros.
                          </li>
                          <li>
                            El Usuario reconoce la titularidad de KM77 o de
                            cualesquiera otros terceros respecto de todos sus
                            derechos de propiedad intelectual e industrial y
                            cualesquiera otros derechos análogos.
                          </li>
                          <li>
                            El Usuario comprende y acepta que el Sitio Web
                            contiene información sobre la que existen derechos
                            exclusivos y se compromete a no proveer o facilitar
                            o de cualquier otro modo hacer accesible el Sitio
                            Web, la Herramienta de valoración y/o la
                            documentación relacionada con la misma a cualquier
                            tercero.
                          </li>
                          <li>
                            El Usuario deberá abstenerse de suprimir, alterar,
                            eludir o manipular cualquier dispositivo de
                            protección o sistema de seguridad que estuviera
                            instalado en el Sitio Web y/o en la Herramienta de
                            valoración.
                          </li>
                          <li>
                            El Usuario se abstendrá de extraer y reutilizar la
                            información contenida en el Sitio Web, ya sea de
                            forma total o de forma parcial, automática o manual,
                            por lo que no podrá, entre otros, aplicar técnicas
                            de “screen-scraping” similares.
                          </li>
                        </ol>
                      </li>
                      <li className="">
                        Propiedad Intelectual sobre los Informes
                        <ol className="list-disc ml-4 md:ml-8 mb-2">
                          <li>
                            El Usuario reconoce que sobre los Informes existen
                            derechos de propiedad intelectual titularidad de
                            KM77 o de terceros y que la adquisición de los
                            Informes en ningún caso supondrá una cesión de tales
                            derechos de propiedad intelectual.
                          </li>
                          <li>
                            El Usuario podrá utilizar los Informes con una
                            finalidad estrictamente doméstica y no podrá
                            revender a terceros ni de cualquier forma utilizar
                            los Informes con fines comerciales.
                          </li>
                          <li>
                            El Usuario se abstendrá de reproducir, distribuir,
                            comunicar públicamente, transformar o de cualquier
                            otra forma explotar los Informes a los que tenga
                            acceso a través del Sitio Web.
                          </li>
                          <li>
                            El Usuario se abstendrá de extraer y reutilizar la
                            información contenida en el Sitio Web, ya sea de
                            forma total o de forma parcial, automática o manual,
                            por lo que no podrá, entre otros, aplicar técnicas
                            de “screen-scraping” similares.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li className="">
                    <b>USOS NO AUTORIZADOS</b>
                    <ol className="list-decimal ml-4 md:ml-8 mb-2">
                      <li className="">
                        Al acceder o utilizar el Sitio Web el Usuario manifiesta
                        y garantiza que cumplirás con lo establecido en los
                        presentes Términos y Condiciones y en la normativa en
                        todo momento.
                      </li>
                      <li className="">
                        En el siguiente listado se incluyen, a título
                        ejemplificativo, algunos de los compromisos que el
                        Usuario asume:
                        <ol className="list-disc ml-4 md:ml-8 mb-2">
                          <li>
                            No usar el Sitio Web de forma alguna que pueda
                            provocar daños, interrupciones, ineficiencias o
                            defectos en su funcionamiento o en dispositivos
                            propios o de un tercero.
                          </li>
                          <li>
                            No utilizar el Sitio Web para la transmisión,
                            instalación o publicación de cualquier virus, código
                            malicioso u otros programas o archivos
                            perjudiciales.
                          </li>
                          <li>
                            No utilizar el Sitio Web en contra de la buena fe,
                            la moral y el orden público;
                          </li>
                          <li>
                            No acceder sin autorización a secciones restringidas
                            del Sitio Web, a otros sistemas o redes conectados o
                            a cualquier servidor de KM77 o terceros
                            colaboradores por medio de pirateo o falsificación,
                            extracción de contraseñas o cualquier otro medio
                            ilegítimo.
                          </li>
                          <li>
                            No quebrantar, o intentar quebrantar, las medidas de
                            seguridad o autenticación del Sitio Web o de
                            cualquier red conectada a la misma, o las medidas de
                            seguridad o protección inherentes a los contenidos
                            ofrecidos en el Sitio Web.
                          </li>
                          <li>
                            No incluir contenido o material de cualquier
                            naturaleza que no sea de tu titularidad.
                          </li>
                          <li>
                            No replicar el Sitio Web, ni aplicar ingeniería
                            inversa, ni descompilar o desensamblar el Sitio Web,
                            salvo en aquellas circunstancias autorizadas por la
                            Ley.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li className="">
                    <b>DURACI&Oacute;N Y TERMINACI&Oacute;N</b>
                    <ol className="list-decimal ml-4 md:ml-8 mb-2">
                      <li className="">
                        Los presentes T&eacute;rminos y Condiciones se
                        mantendr&aacute;n en vigor mientras se mantenga en
                        funcionamiento y accesible el Sitio Web.
                      </li>
                      <li className="">
                        KM77 se reserva el derecho a impedir el acceso del
                        Usuario al Sitio Web, sin necesidad de comunicación
                        previa alguna, entre otros, caso de que KM77 tuviera
                        motivos para creer que un Usuario ha vulnerado o tiene
                        intención de vulnerar los presentes Términos y
                        Condiciones.
                      </li>
                      <li className="">
                        Estos Términos y Condiciones se extinguirán, asimismo,
                        por las causas generales establecidas en la Ley.
                      </li>
                      <li className="">
                        La resolución del acuerdo que constituyen los presentes
                        Términos y Condiciones por cualquier causa supondrá el
                        cese del Usuario en el uso del Sitio Web.
                      </li>
                      <li className="">
                        La terminación no dará al Usuario derecho a
                        indemnización de ningún tipo, a salvo de los daños y
                        perjuicios que pudieran ser causados por dolo o culpa y
                        siempre que sean dictaminados por un juzgado o tribunal.
                      </li>
                    </ol>
                  </li>
                  <li className="">
                    <b>TRATAMIENTO DE DATOS DE CAR&Aacute;CTER PERSONAL</b>
                    <ol className="list-decimal ml-4 md:ml-8 mb-2">
                      <li className="">
                        Durante la utilización del Sitio Web, es posible que sea
                        necesario que el Usuario aporte ciertos datos e
                        información.
                      </li>
                      <li className="">
                        Es muy importante para KM77 que el Usuario tenga clara
                        la política de KM77 en lo relativo a la protección de
                        datos de carácter personal. Por ello, KM77 toma las
                        máximas precauciones al recopilar la información
                        personal y no personal del Usuario.
                      </li>
                      <li className="">
                        Para una clara comprensión de las políticas aplicadas
                        por KM77 en el manejo de información personal y privada,
                        KM77 recomienda que el Usuario visite la{" "}
                        <Link
                          className="text-blue-800"
                          to="/privacidad"
                          onClick={() => logEvent("Política de privacidad")}
                        >
                          Política de privacidad
                        </Link>{" "}
                        y la{" "}
                        <Link
                          className="text-blue-800"
                          to="/cookies"
                          onClick={() => logEvent("Política de cookies")}
                        >
                          Política de cookies
                        </Link>{" "}
                        de KM77.
                      </li>
                    </ol>
                  </li>
                  <li className="">
                    <b>PRECIOS Y PAGOS</b>
                    <ol className="list-decimal ml-4 md:ml-8 mb-2">
                      <li className="">
                        El acceso, la navegaci&oacute;n y la utilizaci&oacute;n
                        del Sitio Web es gratuito.
                      </li>
                      <li className="">
                        No obstante, algunos de los Servicios prestados a través
                        del Sitio Web pueden estar sujetos al pago de una
                        cantidad que variará en función del Informe que el
                        Usuario desee adquirir. Los términos que regulan la
                        adquisición y pago de productos y servicios a través del
                        Sitio Web se encuentran detallados en las{" "}
                        <Link
                          className="text-blue-800"
                          to="/condicionescontratacion"
                          onClick={() =>
                            logEvent("Condiciones Generales de Contratación")
                          }
                        >
                          Condiciones Generales de Contratación
                        </Link>
                        .
                      </li>
                    </ol>
                  </li>
                  <li className="">
                    <b>ENLACES</b>
                    <ol className="list-decimal ml-4 md:ml-8 mb-2">
                      <li className="">
                        El Sitio Web puede contener enlaces de terceros. KM77 no
                        se hace responsable del contenido de dichos enlaces, ni
                        de las políticas de dichas páginas, especialmente en lo
                        relativo a las políticas de privacidad y cookies de
                        terceros. Del mismo modo, KM77 no se hace responsable en
                        caso de que dichos enlaces contengan virus o
                        cualesquiera otros elementos maliciosos.
                      </li>
                      <li className="">
                        El Usuario reconoce y manifiesta expresamente que KM77
                        no responderá frente a él en todo lo que concierne a
                        dichos enlaces y al contenido enlazado, incluyendo, pero
                        no limitándose a la disponibilidad de la información y
                        recursos de dichos terceros.
                      </li>
                      <li className="">
                        KM77 no promociona ni promueve los enlaces y contenidos
                        de terceros que pueden aparecer en el Sitio Web y no
                        será responsable por ningún contenido, publicidad,
                        promoción, producto y/o cualesquiera otros materiales
                        que estén disponibles en las páginas web de terceros.
                      </li>
                      <li className="">
                        Si cualquier Usuario o un tercero observara que los
                        contenidos a los que el Sitio Web de KM77 permiten
                        acceder a través de enlaces pueden ser contrarios a las
                        leyes, la moral o el orden público, deberá informar a
                        KM77 a través de la siguiente dirección de correo
                        electrónico:{" "}
                        <a className="text-blue-800" href="mailto:informes.segundamano@km77.com">
                          {" "}
                          informes.segundamano@km77.com
                        </a>
                        .
                      </li>
                    </ol>
                  </li>
                  <li className="">
                    <b>
                      MANIFESTACIONES, GARANT&Iacute;AS Y EXCLUSI&Oacute;N DE
                      RESPONSABILDAD{" "}
                    </b>
                    <ol className="list-decimal ml-4 md:ml-8 mb-2">
                      <li className="">
                        KM77 no asume responsabilidad por ninguna contingencia
                        derivada del uso del Sitio Web ni de cualesquiera otros
                        elementos del Servicio.
                      </li>
                      <li className="">
                        KM77 no será responsable de los errores o de la falta de
                        exactitud de los datos e información contenidos en este
                        Sitio Web o en los Informes.
                      </li>
                      <li className="">
                        La Herramienta de valoración utiliza técnicas basadas en
                        la comparación de datos de otros vehículos en oferta,
                        por lo que para un vehículo en concreto puede no ser
                        capaz de ofrecer una valoración, en caso de que la
                        información disponible sobre vehículos similares sea
                        limitada. KM77 no se hace responsable en caso de que la
                        Herramienta de valoración no pueda ofrecer al Usuario
                        una valoración del vehículo.
                      </li>
                      <li className="">
                        KM77 empleará sus mejores esfuerzos para que el Sitio
                        Web no contenga y no sea utilizado para la transmisión,
                        instalación o publicación de cualquier virus, código
                        malicioso u otros programas o archivos perjudiciales, o
                        de forma alguna que pueda provocar daños,
                        interrupciones, ineficiencias o defectos en su
                        funcionamiento o en dispositivos de un tercero.
                      </li>
                      <li className="">
                        No obstante lo anterior, KM77 no garantiza ni se hace
                        responsable de: (i) la invulnerabilidad del Sitio Web
                        y/o la inexpugnabilidad de las medidas de seguridad que
                        se adopten en el mismo; (ii) la falta de utilidad o
                        rendimiento del Sitio Web; (iii) los fallos del Sitio
                        Web ocasionados por cualquier tipo de ataque a los
                        servidores de terceros proveedores de servicios de KM77
                        o sus colaboradores, así como los fallos técnicos o de
                        seguridad del sistema de cualquiera de dichos
                        proveedores que impidan el funcionamiento del Sitio Web;
                        (iv) cualquier fallo técnico de cualquier tipo que
                        dificulte, retrase o impida el correcto funcionamiento
                        del Sitio Web, y (v) los daños o perjuicios que cause, a
                        sí mismo o a un tercero, cualquier persona que infrinja
                        los Términos y Condiciones, normas e instrucciones
                        establecidas en el Sitio Web o vulnere de los sistemas
                        de seguridad de la misma.
                      </li>
                      <li className="">
                        En todo caso, KM77 se compromete a adoptar todas las
                        medidas necesarias, dentro de sus posibilidades y del
                        estado de la técnica, para garantizar el funcionamiento
                        del Sitio Web, además de evitar la existencia y
                        transmisión de virus y demás componentes dañinos.
                      </li>
                      <li className="">
                        El Sitio Web se proporciona "tal cual" sin garantías de
                        ningún tipo, expresas o implícitas. El uso del Sitio Web
                        se realiza bajo el propio riesgo del Usuario.
                      </li>
                      <li className="">
                        KM77 no será responsable frente al Usuario ni frente a
                        terceros, ya sea de forma directa o indirecta, por,
                        incluyendo pero no limitándose, daños indirectos y/o
                        accidentales, cualquiera que sea su naturaleza o
                        concepto.
                      </li>
                      <li className="">
                        El Usuario se compromete a responder ante KM77 por
                        cualquier reclamación judicial o extrajudicial, daño o
                        sanción derivada del uso que el Usuario haga del Sitio
                        Web.
                      </li>
                    </ol>
                  </li>
                  <li className="">
                    <b>SUGERENCIAS Y RECLAMACIONES</b>
                    <ol className="list-decimal ml-4 md:ml-8 mb-2">
                      <li className="">
                        El Usuario que desee trasladar una reclamaci&oacute;n a
                        KM77 podr&aacute; hacerlo enviando su queja o consulta a
                        la direcci&oacute;n de correo electr&oacute;nico{" "}
                        <a className="text-blue-800" href="mailto:informes.segundamano@km77.com">
                          {" "}
                          informes.segundamano@km77.com
                        </a>
                        .
                      </li>
                      <li className="">
                        El Usuario podr&aacute; dirigirse a esa misma
                        direcci&oacute;n para plantear cualquier consulta,
                        sugerencia o recomendaci&oacute;n.
                      </li>
                    </ol>
                  </li>
                  <li className="">
                    <b>MISCEL&Aacute;NEA</b>
                    <ol className="list-decimal ml-4 md:ml-8 mb-2">
                      <li className="">
                        KM77 perseguirá el incumplimiento de los presentes
                        Términos y Condiciones, así como cualquier utilización
                        indebida de su Sitio Web y/o de su Servicio ejerciendo
                        todas las acciones civiles y penales que le puedan
                        corresponder en derecho.
                      </li>
                      <li className="">
                        El no ejercicio o ejecución por parte de KM77 de
                        cualquier derecho o disposición contenida en estos
                        Términos y Condiciones no constituirá una renuncia al
                        mismo ni, en su caso, exonerará al Usuario del
                        cumplimiento de sus obligaciones, salvo reconocimiento y
                        acuerdo expreso y por escrito de KM77.
                      </li>
                      <li className="">
                        KM77 podrá modificar en cualquier momento los presentes
                        Términos y Condiciones. La versión de los Términos y
                        Condiciones vigente será aquella que, en cada momento,
                        se encuentre publicada.
                      </li>
                    </ol>
                  </li>
                  <li className="">
                    <b>LEY APLICABLE Y JURISDICCI&Oacute;N </b>
                    <ol className="list-decimal ml-4 md:ml-8 mb-2">
                      <li className="">
                        Los presentes T&eacute;rminos y Condiciones de Uso se
                        rigen por la legislaci&oacute;n espa&ntilde;ola.
                      </li>
                      <li className="">
                        En los casos en los que la legislaci&oacute;n lo
                        permita, las partes, renunciando a su propio fuero,
                        acuerdan someter cualquier conflicto, controversia y/o
                        reclamaci&oacute;n relacionada con estos T&eacute;rminos
                        y Condiciones de Uso a los Jueces y Tribunales de Madrid
                        (Capital).
                      </li>
                      <li className="">
                        Asimismo, los Usuarios tendr&aacute;n en todo momento la
                        posibilidad de acudir a la plataforma de
                        resoluci&oacute;n de litigios en l&iacute;nea de la
                        Comisi&oacute;n Europea a la que se puede acceder a
                        trav&eacute;s de este{" "}
                        <a
                          className="text-blue-800"
                          href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=ES"
                        >
                          enlace
                        </a>
                        .
                      </li>
                    </ol>
                  </li>
                </ol>
                <p>Fecha de &uacute;ltima actualizaci&oacute;n: 17/08/2021</p>
                <p className="">
                  <a
                    className="flex text-blue-800 justify-start items-center"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      history.go(-1);
                    }}
                  >
                    <svg
                      class="fill-current text-blue-800 p-0 m-0 w-8 h-8"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z" />
                    </svg>
                    Volver
                  </a>
                </p>{" "}
              </div>
            </div>
          </div>
        )}
      </Amplitude>
    </div>
  );
}
