import React from "react";
import axios from "axios";
import Select from "react-select";
import userContext from "../userContext";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useHistory, Link } from "react-router-dom";
import madivalogo from "../images/madivalogo.png";
import km77logo from "../images/km77-logo.png";
import { Amplitude } from "@amplitude/react-amplitude";
import amplitude from "amplitude-js";
import { loadStripe } from "@stripe/stripe-js";
import NumberFormat from "react-number-format";
import ReactSlider from "react-slider";

export default function Test() {
  const stripe = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  const [session, setSession] = React.useContext(userContext);
  const { step } = useParams();
  let history = useHistory();
  const [stepMenu, setStepMenu] = React.useState(1);
  const [marcas, setMarcas] = React.useState([]);
  const [marcasTop, setMarcasTop] = React.useState([]);
  const [marca, setMarca] = React.useState(null);
  const [matricula, setMatricula] = React.useState("");
  const [matriculaValida, setMatriculaValida] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [anyo, setAnyo] = React.useState(null);
  const [anyos, setAnyos] = React.useState([]);
  const [modelos, setModelos] = React.useState([]);
  const [modelo, setModelo] = React.useState(null);
  const [combustibles, setCombustibles] = React.useState([]);
  const [combustible, setCombustible] = React.useState(null);
  const [potencias, setPotencias] = React.useState([]);
  const [potencia, setPotencia] = React.useState(null);
  const [kilometros, setKilometros] = React.useState(0);
  const [valoracion, setValoracion] = React.useState(null);
  const [email, setEmail] = React.useState("");
  const [emailValido, setEmailValido] = React.useState(false);
  const [pago, setPago] = React.useState("");
  const [opcion, setOpcion] = React.useState("basico"); //inicializamos el radio con la opcion basica
  const [aceptarCondiciones, setAceptarCondiciones] = React.useState(false);

  React.useEffect(() => {
    function getMarcasTop() {
      axios
        .get(
          `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_SERVER_COCHES_KM77}/marcas/top10`
        )
        .then(function (result) {
          const marcasTop = result.data.map((marca) => {
            marca.value = marca.id;
            return marca;
          });

          setMarcasTop(
            marcasTop.sort(function (a, b) {
              if (a.marca > b.marca) {
                return 1;
              }
              if (a.marca < b.marca) {
                return -1;
              }
              return 0;
            })
          );
        })
        .catch(function (error) {
          toast.error(
            "Error accediendo al servidor, por favor inténtelo más tarde",
            {
              toastId: "errorMarcas",
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            }
          );
        });
    }

    function getMarcas() {
      axios
        .get(
          `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_SERVER_COCHES_KM77}/marcas`
        )
        .then(function (result) {
          const marcas = result.data.map((marca) => {
            marca.value = marca.id;
            return marca;
          });

          setMarcas(
            marcas.sort(function (a, b) {
              if (a.marca > b.marca) {
                return 1;
              }
              if (a.marca < b.marca) {
                return -1;
              }
              return 0;
            })
          );
        })
        .catch(function (error) {
          toast.error(
            "Error accediendo al servidor, por favor inténtelo más tarde",
            {
              toastId: "errorMarcas",
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            }
          );
        });
    }

    function MarcadoLoad() {
      if (stepMenu !== 3) {
        let mensaje = "Page_Step" + (stepMenu === 4 ? 3 : stepMenu);
        if (valoracion && valoracion.estado === "NOVALIDO") {
          mensaje += "NoData";
        }
        amplitude.getInstance().logEvent(mensaje);
      }
    }

    MarcadoLoad();
    getMarcasTop();
    getMarcas();
  }, [session, stepMenu, valoracion]);

  const getAnyos = () => {
    let now = new Date();
    let thisYear = now.getFullYear();
    let anyos = new Array(25).fill(undefined).map((val, idx) => {
      let valor = {
        id: thisYear - idx,
        anyo: thisYear - idx,
        value: thisYear - idx,
      };
      return valor;
    });
    setAnyos(anyos);
  };

  const getModelos = (marcaId, anyoValue) => {
    return axios
      .get(
        `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_SERVER_COCHES_KM77}/modelos?marcaId=${marcaId}&year=${anyoValue}`
      )
      .then(function (result) {
        const modelosFiltrados = [];
        result.data.map((modelo) => {
          modelo.value = modelo.id;
          modelosFiltrados.push(modelo);
          return modelo;
        });

        return modelosFiltrados.sort(function (a, b) {
          if (a.modelo > b.modelo) {
            return 1;
          }
          if (a.modelo < b.modelo) {
            return -1;
          }
          return 0;
        });
      })
      .catch(function (error) {
        toast.error("Error accediendo al servidor", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      });
  };

  const pasarelaStripe = (aceptarCondiciones, email, idPedido, idPrice) => {
    if (aceptarCondiciones) {
      procesoPagoStripe(email, idPedido, idPrice).then((res) => {
        stripe.then((result) =>
          result.redirectToCheckout({ sessionId: res }).then()
        );
      });
    } else {
      toast.error("Debe aceptar las condiciones", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };

  const procesoPagoStripe = (email, idPedido, idPrice) => {
    let body = {
      email: email,
      idPrice: idPrice,
      idPedido: idPedido,
      origin: session,
    };
    let config = { responseType: "text" };

    return axios
      .post(
        `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_SERVER_STRIPE}/getPaymentSession`,
        body,
        config
      )
      .then(function (result) {
        return result.data;
      })
      .catch(function (error) {
        toast.error("Error accediendo al servidor", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      });
  };

  const searchMatricula = (matricula) => {
    let url = `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_SERVER_COCHES_KM77}/informe/reducido?matricula=${matricula}`;
    return axios
      .get(url, { timeout: 8000 })
      .then(function (result) {
        if (result && result.data && result.data.marca) {
          setMarca(result.data.marca);
          getAnyos();

          if (result.data.fechaPrimeraMatricula) {
            let anyoAux = result.data.fechaPrimeraMatricula.substring(0, 4);
            setAnyo({ id: anyoAux, anyo: anyoAux, value: anyoAux });
            setKilometros(kilometrosDefault(anyoAux));

            getModelos(result.data.marca.id, anyoAux).then((resultModelos) => {
              setModelos(resultModelos);
            });
            if (result.data.modelo) {
              var containsHybrid = false;
              setModelo(result.data.modelo);
              getCombustibles(result.data.modelo.id, anyoAux).then(
                (resultCombustibles) => {
                  setCombustibles(resultCombustibles);
                  if (
                    resultCombustibles.filter(
                      (e) => e.combustible.key === "HIBRIDO"
                    ).length === 1
                  ) {
                    containsHybrid = true;
                  }
                  if (!containsHybrid) {
                    if (result.data.potenciaCombustible) {
                      setCombustible(result.data.potenciaCombustible);

                      getPotencias(result.data.potenciaCombustible).then(
                        (resultPotencias) => {
                          setPotencias(resultPotencias);
                          if (resultPotencias && resultPotencias.length === 1) {
                            setPotencia(resultPotencias[0]);
                          }
                        }
                      );
                    } else {
                      if (combustibles.lenght === 1) {
                        setCombustible(combustibles[0]);
                        getPotencias(combustible).then((resultPotencias) => {
                          setPotencias(resultPotencias);
                          if (resultPotencias && resultPotencias.length === 1) {
                            setPotencia(resultPotencias[0]);
                          }
                        });
                      }
                    }
                  }
                }
              );
            }
          }

          setStepMenu(2);
          setLoading(false);
        } else {
          toast.error(
            "No encontramos datos para  " +
              matricula +
              " por favor utiliza el selector de marcas",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            }
          );
          setStepMenu(1);
          setLoading(false);
        }
      })
      .catch(function (error) {
        toast.error("Error accediendo al servidor", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      });
  };

  const getCombustibles = (modeloId, anyoValue) => {
    return axios
      .get(
        `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_SERVER_COCHES_KM77}/combustibleYPotencia?modeloId=${modeloId}&anio=${anyoValue}`
      )
      .then(function (result) {
        const combustiblesFiltrados = [];
        result.data.map((combustible) => {
          combustiblesFiltrados.push(combustible);
          return combustible;
        });

        return combustiblesFiltrados.sort(function (a, b) {
          if (a.value > b.value) {
            return 1;
          }
          if (a.value < b.value) {
            return -1;
          }
          return 0;
        });
      })
      .catch(function (error) {
        toast.error("Error accediendo al servidor", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      });
  };

  const getPotencias = (combustible) => {
    const potenciaFiltrados = [];
    if (combustible.potencias) {
      combustible.potencias.map((potencia) => {
        let potenciaAux = { id: potencia, potencia: potencia, value: potencia };

        potenciaFiltrados.push(potenciaAux);

        return potenciaFiltrados.sort(function (a, b) {
          if (a.potencia > b.potencia) {
            return 1;
          }
          if (a.potencia < b.potencia) {
            return -1;
          }
          return 0;
        });
      });
    } else {
      toast.error("Error accediendo al servidor", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }

    let promise = new Promise(function (resolve, reject) {
      // call resolve if the method succeeds
      resolve(potenciaFiltrados);
    });

    return promise;
  };

  const crearValoracion = (
    modeloId,
    fechaMatriculacion,
    cv,
    combustibleMadiva,
    kilometros,
    matricula,
    bastidor
  ) => {
    let url = `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_SERVER_REPORTS}/km77/report?modeloId=${modeloId}&fechaMatriculacion=${fechaMatriculacion}-01-01&cv=${cv}&combustibleMadiva=${combustibleMadiva}`;
    setStepMenu(3);
    if (kilometros) {
      url = `${url}&kilometrosCliente=${kilometros}`;
    }
    if (matricula) {
      url = `${url}&matricula=${matricula}`;
    }
    if (bastidor) {
      url = `${url}&bastidor=${bastidor}`;
    }
    return axios
      .get(url, { timeout: 300000 })
      .then(function (result) {
        setStepMenu(4);
        setValoracion(result.data);
      })
      .catch(function (error) {
        toast.error("Error accediendo al servidor", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setValoracion({ estado: "NOVALIDO" });
        setStepMenu(4);
      });
  };

  const kilometrosDefault = (anyo) => {
    let kilometrosDefault = 1000;
    let now = new Date();
    let thisYear = now.getFullYear();
    let antiguedad = thisYear - parseInt(anyo);
    if (antiguedad > 1) {
      kilometrosDefault = antiguedad * 15000;
    }

    return kilometrosDefault;
  };

  class MarcasSelect extends React.Component {
    render() {
      const selectMarca = (selectedMarca) => {
        setMarca(selectedMarca);
        getAnyos();
        setAnyo(null);
        setModelo(null);
        setCombustible(null);
        setPotencia(null);
        setKilometros(0);
        setStepMenu(2);
      };
      let marcasAux = this.props.marcas;
      return (
        <div className="col s10 m5">
          <Amplitude>
            {({ logEvent }) => (
              <Select
                isClearable
                isSearchable
                isCacheable
                options={marcasAux}
                getOptionLabel={(option) => `${option.marca}`}
                onChange={(e) => {
                  logEvent("Step1_MarcaSelect");
                  selectMarca(e);
                }}
                placeholder="Busca la marca"
                value={marca}
                menuPlacement="top"
              />
            )}
          </Amplitude>
        </div>
      );
    }
  }

  class TopMarcas extends React.Component {
    handleMouseEnter(event) {
      event.target.src = `${process.env.REACT_APP_API_SERVER}/imagegenerator/logos?type=COCHES&brand=${event.target.dataset["marca"]}&height=72&color=1E40AF&format=PNG`;
    }
    handleMouseOut(event) {
      event.target.src = `${process.env.REACT_APP_API_SERVER}/imagegenerator/logos?type=COCHES&brand=${event.target.dataset["marca"]}&height=72&color=9CA3AF&format=PNG`;
    }
    handleClick(event) {
      let obj = {
        id: event.target.dataset["id"],
        marca: event.target.dataset["marca"],
        value: event.target.dataset["id"],
      };
      setMarca(obj);
      getAnyos();
      setAnyo(null);
      setModelo(null);
      setCombustible(null);
      setPotencia(null);
      setKilometros(0);
      setStepMenu(2);
    }

    render() {
      let topMarcas = this.props.topMarcas;

      return (
        <div className="flex">
          {topMarcas &&
            topMarcas.map((item) => (
              <Amplitude key={item.id}>
                {({ logEvent }) => (
                  <div
                    className="rounded-md border border-blue-800 p-2 m-2 cursor-pointer"
                    key={item.id}
                  >
                    <img
                      alt={item.marca}
                      data-id={item.id}
                      data-marca={item.marca}
                      src={`${process.env.REACT_APP_API_SERVER}/imagegenerator/logos?type=COCHES&brand=${item.marca}&height=72&color=9CA3AF&format=PNG`}
                      onMouseEnter={this.handleMouseEnter}
                      onMouseOut={this.handleMouseOut}
                      onClick={(e) => {
                        logEvent("Step1_MarcaGrande", this.handleClick(e));
                      }}
                      className=""
                    />
                  </div>
                )}
              </Amplitude>
            ))}
        </div>
      );
    }
  }

  class AnyoInput extends React.Component {
    render() {
      const selectAnyo = (selectedAnyo) => {
        setAnyo(selectedAnyo);
        if (selectedAnyo) {
          getModelos(marca.id, selectedAnyo.value).then((result) => {
            setModelos(result);
          });
          setKilometros(kilometrosDefault(selectedAnyo.value));
        } else {
          setModelos(null);
          setKilometros(0);
        }
        setModelo(null);
        setCombustible(null);
        setPotencia(null);
      };

      const logoCalendario = () => ({
        alignItems: "center",
        display: "flex",

        ":before": {
          backgroundImage: `url("/calendar-icon-png-4100.png")`,
          borderRadius: 0,
          content: '" "',
          display: "block",
          marginRight: 10,
          height: 35,
          width: 35,
        },
      });

      const colourStyles = {
        input: (styles) => ({ ...styles, ...logoCalendario() }),
        placeholder: (styles) => ({ ...styles, ...logoCalendario() }),
        singleValue: (styles) => ({ ...styles, ...logoCalendario() }),
      };

      let anyosAux = this.props.anyos;

      return (
        <div className="row">
          <div className="col s10 m5">
            <Amplitude>
              {({ logEvent }) => (
                <Select
                  isClearable
                  isSearchable
                  isDisabled={marca === null}
                  options={anyosAux}
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.anyo}`}
                  placeholder="¿Cuándo se matriculó?"
                  onChange={(e) => {
                    //logEvent("AñoSeleccionado");
                    let now = new Date();
                    let thisYear = now.getFullYear();
                    if (thisYear - e.value < 26) {
                      selectAnyo(e);
                    } else {
                      toast.error(
                        "No se permite seleccionar más de 25 años de antigüedad",
                        {
                          toastId: "errorAnyos",
                          position: "top-center",
                          autoClose: 3000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: false,
                          progress: undefined,
                        }
                      );
                    }
                  }}
                  value={anyo}
                />
              )}
            </Amplitude>
          </div>
        </div>
      );
    }
  }

  class ModeloSelect extends React.Component {
    render() {
      const selectModelo = (selectedModelo) => {
        setModelo(selectedModelo);
        if (selectedModelo) {
          getCombustibles(selectedModelo.id, anyo.value).then((result) => {
            setCombustibles(result);
            if (result && result.length === 1) {
              setCombustible(result[0]);
              getPotencias(result[0]).then((resultPotencias) => {
                setPotencias(resultPotencias);
                if (resultPotencias && resultPotencias.length === 1) {
                  setPotencia(resultPotencias[0]);
                }
              });
            }
          });
        } else {
          setCombustibles(null);
        }
        setCombustible(null);
        setPotencia(null);
      };

      const logoMarca = () => ({
        alignItems: "center",
        display: "flex",

        ":before": {
          backgroundImage: `url("${process.env.REACT_APP_API_SERVER}/imagegenerator/logos?type=COCHES&brand=${marca.marca}&height=35&format=PNG")`,
          borderRadius: 0,
          content: '" "',
          display: "block",
          marginRight: 10,
          height: 35,
          width: 35,
        },
      });

      const colourStyles = {
        input: (styles) => ({ ...styles, ...logoMarca() }),
        placeholder: (styles) => ({ ...styles, ...logoMarca() }),
        singleValue: (styles) => ({ ...styles, ...logoMarca() }),
      };

      return (
        <div className="row">
          <div className="col s10 m5">
            <Amplitude>
              {({ logEvent }) => (
                <Select
                  isClearable
                  isSearchable
                  isDisabled={anyo === null}
                  options={modelos}
                  styles={colourStyles}
                  noOptionsMessage={() => "No se encontraron modelos"}
                  placeholder="Busque el modelo"
                  getOptionLabel={(option) => `${option.modelo}`}
                  onChange={(e) => {
                    //logEvent("ModeloSeleccionado");
                    selectModelo(e);
                  }}
                  value={modelo}
                />
              )}
            </Amplitude>
          </div>
        </div>
      );
    }
  }

  class CombustibleSelect extends React.Component {
    render() {
      const selectCombustible = (selectedCombustible) => {
        setCombustible(selectedCombustible);
        if (selectedCombustible) {
          getPotencias(selectedCombustible).then((result) => {
            setPotencias(result);
            if (result && result.length === 1) {
              setPotencia(result[0]);
            }
          });
        } else {
          setPotencias(null);
        }
        setPotencia(null);
      };
      const logoSurtidor = () => ({
        alignItems: "center",
        display: "flex",

        ":before": {
          backgroundImage: `url("/surtidor.png")`,
          borderRadius: 0,
          content: '" "',
          display: "block",
          marginRight: 10,
          height: 31,
          width: 35,
        },
      });

      const colourStyles = {
        input: (styles) => ({ ...styles, ...logoSurtidor() }),
        placeholder: (styles) => ({ ...styles, ...logoSurtidor() }),
        singleValue: (styles) => ({ ...styles, ...logoSurtidor() }),
      };

      return (
        <div className="row">
          <div className="col s10 m5">
            <Amplitude>
              {({ logEvent }) => (
                <Select
                  isClearable
                  isSearchable
                  isDisabled={modelo === null}
                  options={combustibles}
                  styles={colourStyles}
                  noOptionsMessage={() => "No se encontraron combustibles"}
                  placeholder="¿Qué combustible utiliza?"
                  getOptionLabel={(option) => `${option.combustible.value}`}
                  onChange={(e) => {
                    //logEvent("CombustibleSeleccionado");
                    selectCombustible(e);
                  }}
                  value={combustible}
                />
              )}
            </Amplitude>
          </div>
        </div>
      );
    }
  }

  class PagoRadio extends React.Component {
    constructor(props) {
      super(props);
      this.state = { value: props.selectedOption };
      this.onValueChange = this.onValueChange.bind(this);
    }
    onValueChange(event) {
      this.setState({ value: event.target.value });
      setPago(event.target.value);
    }
    render() {
      return (
        <div className="my-6">
          <div className="text-gray-500">Elige la forma de pago:</div>
          <div className="mt-2 flex">
            <div>
              <label>
                <input
                  className="mr-2"
                  name="formapago"
                  type="radio"
                  value="tarjeta"
                  checked={this.state.value === "tarjeta"}
                  onChange={this.onValueChange}
                />
                <span>Pago con tarjeta</span>
              </label>
            </div>
            <div className="input-field col s9">
              <label>
                <input
                  className="ml-6 mr-2"
                  name="formapago"
                  type="radio"
                  value="bizum"
                  checked={this.state.value === "bizum"}
                  onChange={this.onValueChange}
                />
                <span>Pago con Bizum</span>
              </label>
            </div>
          </div>
        </div>
      );
    }
  }

  class OpcionRadio extends React.Component {
    constructor(props) {
      super(props);
      this.state = { value: props.selectedOption };
      this.onValueChange = this.onValueChange.bind(this);
    }
    onValueChange(event) {
      this.setState({ value: event.target.value });
      setOpcion(event.target.value);
    }
    render() {
      return (
        <div className="my-6">
          <div className="text-gray-500">Elige el tipo de informe:</div>
          <div className="mt-2 flex">
            <div>
              <label>
                <p>
                  Informe detallado{" "}
                  <Amplitude>
                    {({ logEvent }) => (
                      <Link
                        to="/docs/informe_ejemplo.pdf"
                        target="_blank"
                        className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                        onClick={() => {
                          logEvent("click_ejemplo_pago");
                        }}
                      >
                        (ejemplo)
                      </Link>
                    )}
                  </Amplitude>
                  :
                </p>
                <input
                  className="ml-6 mr-2"
                  name="tipoinforme"
                  type="radio"
                  value="basico"
                  checked={this.state.value === "basico"}
                  onChange={this.onValueChange}
                />
                <span>5€</span>
              </label>
            </div>
          </div>
        </div>
      );
    }
  }

  class PotenciaSelect extends React.Component {
    render() {
      const selectPotencia = (selectedPotencia) => {
        setPotencia(selectedPotencia);
      };
      const logoPotencia = () => ({
        alignItems: "center",
        display: "flex",

        ":before": {
          backgroundImage: `url("/potencia.png")`,
          borderRadius: 0,
          content: '" "',
          display: "block",
          marginRight: 10,
          height: 35,
          width: 35,
        },
      });
      const colourStyles = {
        input: (styles) => ({ ...styles, ...logoPotencia() }),
        placeholder: (styles) => ({ ...styles, ...logoPotencia() }),
        singleValue: (styles) => ({ ...styles, ...logoPotencia() }),
      };

      return (
        <div className="row">
          <div className="col s10 m5">
            <Amplitude>
              {({ logEvent }) => (
                <Select
                  isClearable
                  isSearchable
                  isDisabled={combustible === null}
                  options={potencias}
                  styles={colourStyles}
                  noOptionsMessage={() => "No se encontraron potencias"}
                  placeholder="¿Cuál es su potencia?"
                  getOptionLabel={(option) => `${option.potencia}`}
                  onChange={(e) => {
                    //logEvent("PotenciaSeleccionada");
                    selectPotencia(e);
                  }}
                  value={potencia}
                />
              )}
            </Amplitude>
          </div>
        </div>
      );
    }
  }

  const emailChange = (event) => {
    if (event.target.value) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      var validado = pattern.test(event.target.value);
      setEmailValido(validado);
      setEmail(event.target.value);
    }
  };

  const matriculaChange = (event) => {
    if (event.target.value) {
      var pattern = new RegExp(/^((\d{4}\s[A-Z]{3}$)|(\d{4}[A-Z]{3}$))/i);
      var validado = pattern.test(event.target.value);
      setMatriculaValida(validado);
      setMatricula(event.target.value);
    } else {
      setMatriculaValida(false);
      setMatricula(event.target.value);
    }
  };

  return (
    <div className="m-8 font-mulish mb-10">
      <div className="flex items-center justify-center mb-8">
        <div className="text-3xl text-blue-800">INFORME DETALLADO</div>
        <div className="ml-2">
          <Link to="/" className="flex">
            <img src={madivalogo} alt="Madiva" className="w-14 mx-2" />
            <img src={km77logo} alt="Km77" className="w-14" />
          </Link>
        </div>
      </div>
      <div className="flex items-center justify-center mb-8">
        <div className="flex flex-col items-center">
          {stepMenu === 1 && (
            <React.Fragment>
              <div className="rounded-t-md h-10 w-full border border-blue-800  bg-blue-800 flex justify-center content-center">
                <div className="text-3xl text-center align-top font-bold text-white">
                  1
                </div>
              </div>
              <div className="rounded-b-md border border-blue-800 text-xs w-20 md:w-24 md:w-24 text-center bg-blue-800 text-white">
                Identificación
              </div>
            </React.Fragment>
          )}
          {stepMenu > 1 && (
            <React.Fragment>
              <div className="rounded-t-md h-10 w-full border border-yellow-600  bg-yellow-600 flex justify-center content-center">
                <div className="text-3xl text-center align-top font-bold text-white">
                  <svg
                    className="text-white mt-1 fill-current h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                  </svg>
                </div>
              </div>
              <div className="rounded-b-md border border-yellow-600 text-xs w-20 md:w-24 text-center bg-yellow-600 text-white">
                Identificación
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="h-1 w-10 border border-blue-800 bg-blue-800 flex justify-center content-center"></div>
        <div className="flex flex-col items-center">
          {stepMenu === 2 && (
            <React.Fragment>
              <div className="rounded-t-md h-10 w-full border border-blue-800  bg-blue-800 flex justify-center content-center">
                <div className="text-3xl text-center align-top font-bold text-white">
                  2
                </div>
              </div>
              <div className="rounded-b-md border border-blue-800 text-xs w-20 md:w-24 text-center bg-blue-800 text-white">
                Detalles
              </div>
            </React.Fragment>
          )}
          {stepMenu < 2 && (
            <React.Fragment>
              <div className="rounded-t-md h-10 w-full border border-blue-800 flex justify-center content-center">
                <div className="text-3xl text-center align-top font-bold text-blue-800">
                  2
                </div>
              </div>
              <div className="rounded-b-md border border-blue-800 text-xs text-gray-600 w-20 md:w-24 text-center">
                Detalles
              </div>
            </React.Fragment>
          )}
          {stepMenu > 2 && (
            <React.Fragment>
              <div className="rounded-t-md h-10 w-full border border-yellow-600  bg-yellow-600 flex justify-center content-center">
                <div className="text-3xl text-center align-top font-bold text-white">
                  <svg
                    className="text-white mt-1 fill-current h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                  </svg>
                </div>
              </div>
              <div className="rounded-b-md border border-yellow-600 text-xs w-20 md:w-24 text-center bg-yellow-600 text-white">
                Detalles
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="h-1 w-10 border border-blue-800 bg-blue-800 flex justify-center content-center"></div>
        <div className="flex flex-col items-center">
          {stepMenu === 3 && (
            <React.Fragment>
              <div className="rounded-t-md h-10 w-full border border-blue-800  bg-blue-800 flex justify-center content-center">
                <div className="text-3xl text-center align-top font-bold text-white">
                  3
                </div>
              </div>
              <div className="rounded-b-md border border-blue-800 text-xs w-20 md:w-24 text-center bg-blue-800 text-white">
                Verificación
              </div>
            </React.Fragment>
          )}
          {stepMenu < 3 && (
            <React.Fragment>
              <div className="rounded-t-md h-10 w-full border border-blue-800 flex justify-center content-center">
                <div className="text-3xl text-center align-top font-bold text-blue-800">
                  3
                </div>
              </div>
              <div className="rounded-b-md border border-blue-800 text-xs text-gray-600 w-20 md:w-24 text-center">
                Verificación
              </div>
            </React.Fragment>
          )}
          {stepMenu > 3 && (
            <React.Fragment>
              <div className="rounded-t-md h-10 w-full border border-yellow-600  bg-yellow-600 flex justify-center content-center">
                <div className="text-3xl text-center align-top font-bold text-white">
                  <svg
                    className="text-white mt-1 fill-current h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                  </svg>
                </div>
              </div>
              <div className="rounded-b-md border border-yellow-600 text-xs w-20 md:w-24 text-center bg-yellow-600 text-white">
                Verificación
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="h-1 w-10 border border-blue-800 bg-blue-800 flex justify-center content-center"></div>
        <div className="flex flex-col items-center">
          {stepMenu === 4 && (
            <React.Fragment>
              <div className="rounded-t-md h-10 w-full border border-blue-800  bg-blue-800 flex justify-center content-center">
                <div className="text-3xl text-center align-top font-bold text-white">
                  4
                </div>
              </div>
              <div className="rounded-b-md border border-blue-800 text-xs w-20 md:w-24 text-center bg-blue-800 text-white">
                Finalización
              </div>
            </React.Fragment>
          )}
          {stepMenu < 4 && (
            <React.Fragment>
              <div className="rounded-t-md h-10 w-full border border-blue-800 flex justify-center content-center">
                <div className="text-3xl text-center align-top font-bold text-blue-800">
                  4
                </div>
              </div>
              <div className="rounded-b-md border border-blue-800 text-xs text-gray-600 w-20 md:w-24 text-center">
                Finalización
              </div>
            </React.Fragment>
          )}
          {stepMenu > 4 && (
            <React.Fragment>
              <div className="rounded-t-md h-10 w-full border border-yellow-600  bg-yellow-600 flex justify-center content-center">
                <div className="text-3xl text-center align-top font-bold text-white">
                  <svg
                    className="text-white mt-1 fill-current h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                  </svg>
                </div>
              </div>
              <div className="rounded-b-md border border-yellow-600 text-xs w-20 md:w-24 text-center bg-yellow-600 text-white">
                Finalización
              </div>
            </React.Fragment>
          )}
        </div>
      </div>

      {stepMenu === 1 && loading && (
        <div className="flex mt-20 justify-center">
          <svg
            className="animate-spin text-blue-800 fill-current h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M10 3v2a5 5 0 0 0-3.54 8.54l-1.41 1.41A7 7 0 0 1 10 3zm4.95 2.05A7 7 0 0 1 10 17v-2a5 5 0 0 0 3.54-8.54l1.41-1.41zM10 20l-4-4 4-4v8zm0-12V0l4 4-4 4z" />
          </svg>
          <div className="text-blue-800 ml-3">Consultando matrícula...</div>
        </div>
      )}

      {stepMenu === 1 && !loading && (
        <div className="max-w-xl mx-auto">
          <div className="text-blue-800 text-xl mb-2">
            ¿Quieres información de un vehículo concreto?
          </div>
          <div className="flex items-end mb-6">
            <div className="mx-2">
              <label
                className="block text-gray-600 text-sm font-bold"
                htmlFor="matricula"
              >
                Danos la matrícula
              </label>
              <div className="input-field col s10 m5">
                <input
                  id="matricula"
                  name="matricula"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={matricula}
                  onChange={matriculaChange}
                  placeholder="Matrícula"
                />
              </div>
            </div>
            <div className="mx-2">
              <Amplitude>
                {({ logEvent }) => (
                  <button
                    className={
                      (matriculaValida
                        ? "bg-blue-800 hover:bg-blue-500 text-white"
                        : "bg-gray-500 text-gray-200 disabled") +
                      " font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    }
                    type="button"
                    onClick={() => {
                      if (matriculaValida) {
                        setLoading(true);
                        searchMatricula(matricula);
                        logEvent("Step1_Matricula");
                      }
                    }}
                  >
                    Consultar
                  </button>
                )}
              </Amplitude>
            </div>
          </div>
          <div className="text-blue-800 text-xl mt-8 mb-2">
            O... ¿Quieres información de un modelo de una marca?
          </div>
          <TopMarcas topMarcas={marcasTop.slice(0, 4)} />
          <TopMarcas topMarcas={marcasTop.slice(4, 8)} />
          <div className="text-blue-800 text-md mb-2">Otras marcas</div>

          <MarcasSelect marcas={marcas} height="2.4rem" />
        </div>
      )}

      {stepMenu === 2 && (
        <div className="max-w-xl mx-auto">
          <div className="my-6">
            <AnyoInput anyos={anyos} />
          </div>
          <div className="my-6">
            <ModeloSelect />
          </div>
          <div className="my-6">
            <CombustibleSelect />
          </div>
          <div className="my-6">
            <PotenciaSelect />
          </div>
          <div className="mt-6">
            <div className="block text-gray-400 text-sm font-bold">
              Kilometraje aproximado
            </div>
          </div>
          <div className="mt-2 mb-6 flex items-center">
            <div className="w-3/5 mx-3 border">
              <Amplitude>
                {({ logEvent }) => (
                  <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="border border-white bg-white rounded-xl md:rounded h-5 w-5 md:h-2 md:w-2"
                    trackClassName="bg-blue-500 h-5 md:h-2  rounded-xl md:rounded "
                    value={kilometros}
                    step={1000}
                    min={0}
                    onChange={(value, index) => {
                      logEvent("Step2_Kilometros");
                      setKilometros(value);
                    }}
                    max={350000}
                    renderThumb={(props, state) => <div {...props}>{""}</div>}
                  />
                )}
              </Amplitude>
            </div>
            <div className="w-2/5 h-4">
              <NumberFormat
                className="text-blue-800"
                displayType="text"
                thousandSeparator={"."}
                value={kilometros}
                decimalSeparator={","}
              />
            </div>
          </div>
          <div className="mb-6 text-center">
            <Amplitude>
              {({ logEvent }) => (
                <button
                  disabled={potencia === null}
                  className={
                    (potencia != null
                      ? "bg-blue-800 hover:bg-blue-500 text-white"
                      : "bg-gray-500 text-gray-200 disabled") +
                    " font-bold py-2 px-4 w-32 rounded focus:outline-none focus:shadow-outline"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    logEvent("Step2_Continuar");
                    crearValoracion(
                      modelo.id,
                      anyo.id,
                      potencia.id,
                      combustible.combustible.key,
                      kilometros
                    );
                  }}
                >
                  Continuar
                </button>
              )}
            </Amplitude>
          </div>
        </div>
      )}

      {stepMenu === 3 && (
        <div className="flex mt-20 justify-center">
          <svg
            className="animate-spin text-blue-800 fill-current h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M10 3v2a5 5 0 0 0-3.54 8.54l-1.41 1.41A7 7 0 0 1 10 3zm4.95 2.05A7 7 0 0 1 10 17v-2a5 5 0 0 0 3.54-8.54l1.41-1.41zM10 20l-4-4 4-4v8zm0-12V0l4 4-4 4z" />
          </svg>
          <div className="font-mulish text-blue-800 ml-3">
            Comprobando si tenemos datos suficientes para crear un informe de
            ese vehículo...
          </div>
        </div>
      )}

      {valoracion && valoracion.estado !== "VALIDO" && (
        <div className="flex mt-20 justify-center">
          <div className="text-center">
            <div className="text-2xl text-blue-800">Lo sentimos</div>
            <div className="text-blue-800 mt-4">
              No hemos encontrado información suficiente para elaborar un
              informe de ese modelo de vehículo.
            </div>

            <div className="text-blue-800 mt-4">
              <p className="">
                <a
                  className="flex text-blue-800 justify-start items-center"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setStepMenu(1);
                    setValoracion(undefined);
                    history.push("/steps/1");
                  }}
                >
                  <svg
                    class="fill-current text-blue-800 p-0 m-0 w-8 h-8"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z" />
                  </svg>
                  Pruebe otro vehículo
                </a>
              </p>
            </div>
          </div>
        </div>
      )}

      {valoracion && valoracion.estado === "VALIDO" && (
        <div className="max-w-xl mx-auto">
          <div className="flex mt-20 justify-center">
            <div className="text-center">
              <div className="text-2xl text-blue-800">¡Ya está!</div>
              <div className="text-blue-800 text-xl">
                Tenemos tu informe listo
              </div>
            </div>
          </div>
          <div className="mt-6">
            <div className="block text-gray-400 text-sm font-bold">
              ¿Dónde te lo enviamos?
            </div>
          </div>
          <div className="mb-6">
            <div className="">
              <div className="">
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={email}
                  onChange={emailChange}
                  placeholder="Dirección de correo electrónico"
                />
              </div>
            </div>
          </div>
          <Amplitude>
            {({ logEvent }) => (
              <React.Fragment>
                <div className="mb-6">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="mx-3"
                      name="privacidad"
                      id="aceptaCondicionesId"
                      value={aceptarCondiciones}
                      onClick={() => {
                        //logEvent("AceptaCondiciones");
                        setAceptarCondiciones(!aceptarCondiciones);
                      }}
                    />
                    <span>Acepto la política de privacidad</span>
                  </label>
                </div>

                <OpcionRadio selectedOption={opcion} />

                <div className="mb-6 text-center">
                  <button
                    disabled={!emailValido || !opcion}
                    className={
                      (!emailValido || !opcion
                        ? "bg-gray-500 text-gray-200 disabled"
                        : "bg-blue-800 hover:bg-blue-500 text-white") +
                      " font-bold py-2 px-4 w-32 rounded focus:outline-none focus:shadow-outline"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      logEvent("Step3_Enviar");
                      if (opcion === "basico") {
                        pasarelaStripe(
                          aceptarCondiciones,
                          email,
                          valoracion.id,
                          `${process.env.REACT_APP_STRIPE_ID_PRICE_BASIC}`
                        );
                      } else {
                        //pasarela(aceptarCondiciones, email, "COCHESBASIC", valoracion.id, pago );
                        pasarelaStripe(
                          aceptarCondiciones,
                          email,
                          valoracion.id,
                          `${process.env.REACT_APP_STRIPE_ID_PRICE_PREMIU}`
                        );
                      }
                    }}
                  >
                    <span className="button-text">Enviar</span>
                  </button>
                </div>
              </React.Fragment>
            )}
          </Amplitude>
        </div>
      )}
    </div>
  );
}
