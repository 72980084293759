import React from "react";
import { Link } from "react-router-dom";
import madivalogo from "../images/madivalogo.png";
import km77logo from "../images/km77-logo.png";

export default function FAQS() {
  return (
    <div className="container main static">
      <Link to="/">
        <img src={madivalogo} alt="Madiva" className="logo right" />
        <img src={km77logo} alt="Madiva" className="logo right" />
      </Link>

      <div className="car">
        <div className="container">
          <h1 className="text-highlight">Preguntas Frecuentes</h1>
          <p class="c0">
            <b>
              <span class="c1">&iquest;Cu&aacute;nto vale tu coche? </span>
            </b>
          </p>
          <p class="c0">
            <span>
              Cada coche vale lo que alguien est&eacute; dispuesto a pagar por
              &eacute;l. No hay otro valor en el mercado y nosotros, en km77, no
              podemos saber cu&aacute;nto dinero puede estar dispuesto alguien a
              pagar por un coche concreto, con una carrocer&iacute;a impoluta o
              mal repintada, con la ITV pasada con buena nota o con todos los
              amortiguadores desfallecidos. Lo que hacemos, con ayuda de una
              empresa externa,{" "}
            </span>
            <span>es un an&aacute;lisis </span>
            <span>estad&iacute;stico </span>
            <span>
              lo m&aacute;s actualizado y completo posible de la oferta de
              coches similares{" "}
            </span>
            <span>
              y una regresi&oacute;n para determinar un valor probable de
              mercado
            </span>
            <span class="c2">
              . Pero no es lo mismo intentar vender un coche en una
              poblaci&oacute;n de 500 habitantes que en una ciudad de tres
              millones. Por tanto, no garantizamos que alguien est&eacute;
              dispuesto ni a comprar ni a vender un coche a un precio similar al
              dato que ofrecemos en esta p&aacute;gina. Nos comprometemos a
              hacer los c&aacute;lculos con rigor y esperamos que sean
              &uacute;tiles a un alto porcentaje de personas.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              <b>&iquest;Qui&eacute;n me va a pagar m&aacute;s por mi coche?</b>
            </span>
          </p>
          <p class="c0">
            <span class="c2">
              El mejor precio de venta normalmente se consigue en operaciones
              entre particulares. Y puede vender a mejor precio qui&eacute;n no
              tiene prisa por vender, si bien los coches tambi&eacute;n se
              deprecian con el paso del tiempo. Para vender a un particular
              conviene tener el coche bien cuidado, tener el libro de
              mantenimiento al d&iacute;a y la ITV reci&eacute;n pasada.
              Adem&aacute;s, los particulares tambi&eacute;n podemos contratar
              garant&iacute;as mec&aacute;nicas, por lo que en principio no es
              necesario utilizar los servicios de una empresa de compraventa.
              Sin embargo, las empresas de compraventa compran y pagan de
              inmediato y resuelven un problema cuando la intenci&oacute;n es
              vender con prontitud.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c6">
              <b>
                &iquest;C&oacute;mo funciona el valorador de coches de km77?
              </b>
            </span>
            <span class="c2">&nbsp;</span>
          </p>
          <p class="c0">
            <span>
              Para conocer el valor que alguien puede ofrecerte por tu coche o
              al que alguien puede estar dispuesto a vender su coche, lo
              &uacute;nico que necesitamos es conocer de qu&eacute; coche se
              trata y en qu&eacute; momento fue matriculado.
            </span>
          </p>
          <p class="c0">
            <span>Por eso,</span>
            <span>&nbsp;s&oacute;lo </span>
            <span>damos</span>
            <span>&nbsp;</span>
            <span>precios de tasaci&oacute;n de</span>
            <span>&nbsp;</span>
            <span>coches de menos de </span>
            <span>15</span>
            <span class="c2">
              &nbsp;a&ntilde;os. El campo en el que te pedimos el n&uacute;mero
              de kil&oacute;metros actual del coche es opcional, porque el
              precio que ofrecemos lo obtenemos del conjunto de coches como el
              tuyo que en estos momentos est&aacute;n en proceso de
              compra-venta. Si nos das el kilometraje podremos ajustar mejor el
              precio, pero incluso sin kilometraje podremos avanzar un valor. Si
              el coche en cuesti&oacute;n recorre muchos m&aacute;s
              kil&oacute;metros de lo que es habitual para los usuarios de cada
              coche concreto, el precio final de compra-venta ser&aacute;
              m&aacute;s bajo. Si recorre menos, la valoraci&oacute;n
              ser&aacute; superior.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              <b>
                &iquest;Qu&eacute; informaci&oacute;n m&iacute;a necesitan para
                darme el dato de tasaci&oacute;n de mi coche?
              </b>
            </span>
          </p>
          <p class="c0">
            <span class="c2">
              S&oacute;lo necesitamos que nos indiques de qu&eacute; coche se
              trata y la antig&uuml;edad del vehiculo darte la respuesta
              inmediatamente. S&oacute;lo te pediremos un correo
              electr&oacute;nico para hacerte llegar el informe.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c6">
              <b>&iquest;Cu&aacute;nto me dan por mi coche de segunda mano?</b>
            </span>
          </p>
          <p class="c0">
            <span class="c2">&nbsp;En km77 no compramos ni vendemos</span>
            <span class="c2">
              coches, ni nuevos ni usados. &Uacute;nicamente damos la mejor
              informaci&oacute;n que somos capaces para que nuestros usuarios
              puedan tomar las mejores decisiones de compra y de venta. Por
              tanto no damos nada por su coche de segunda mano, pero si
              indicamos un precio razonable (si disponemos de &eacute;l) para
              cerrar la operaci&oacute;n siempre que haya alguien dispuesto a
              realizarla.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              <b>
                &iquest;Qu&eacute; tengo que hacer si no encuentro mi coche en
                el listado?
              </b>
            </span>
          </p>
          <p class="c0">
            <span class="c2">
              En nuestra base de datos s&oacute;lo tenemos los coches que se han
              vendido desde el a&ntilde;o 2001, por lo que si buscas un coche
              matriculado antes de esa fecha, no lo vas a encontrar. Para
            </span>
            <span class="c2">
              encontrar precios de coches anteriores a 2001 tendr&aacute;s que
              buscar en otro lugar. Tampoco
            </span>
            <span class="c2">
              encontrar&aacute;s algunos coches de series limitadas. En esos
              casos, te recomendamos que
            </span>
            <span class="c2">
              selecciones otro coche con las mismas caracter&iacute;sticas y del
              mismo a&ntilde;o, aunque el
            </span>
            <span class="c2">
              equipamiento sea diferente, porque el precio, probablemente, no
              difiera mucho.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              <b>
                &iquest;A qu&eacute; se debe la discrepancia con otros
                valoradores?
              </b>
            </span>
          </p>
          <p class="c0">
            <span>
              Cada valorador utiliza m&eacute;todos distintos y fuentes
              distintas para realizar sus c&aacute;lculos y utiliza criterios
              diferentes para hacer la regresi&oacute;n de las diferentes cifras
              encontradas a una recta que permita determinar un valor
              &uacute;nico para cada coche, con cada kilometraje y de cada
              a&ntilde;o. Las diferencias de precios son perfectamente normales,
              porque no son iguales ni{" "}
            </span>
            <span>muestras de selecci&oacute;n</span>
            <span class="c2">
              &nbsp;de testigos ni los m&eacute;todos para obtener el precio
              final.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              <b>
                &iquest;Por qu&eacute; el valorador no da el precio de mi coche?
              </b>
            </span>
          </p>
          <p class="c0">
            <span class="c2">
              Si aparece el mensaje &laquo;Lo sentimos, no podemos darte
              valoraci&oacute;n para el coche
            </span>
            <span class="c2">
              seleccionado&raquo; es porque no disponemos de datos suficientes
              para estimar el
            </span>
            <span>
              precio, bien porque es un modelo muy reciente o porque hay pocos
              en venta y no disponemos de datos suficientes para su
              c&aacute;lculo.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              <b>
                &iquest;Cu&aacute;nto de fiable es la curva de
                depreciaci&oacute;n de mi coche?
              </b>
            </span>
          </p>
          <p class="c0">
            <span class="c2">
              Las curvas de depreciaci&oacute;n las calculamos por un
              an&aacute;lisis hist&oacute;rico de los valores residuales de los
              coches, que intentamos que resulte preciso pero en algunos casos
              puede estar sometido a errores considerables, por diferentes
              motivos.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              <b>
                &iquest;Cu&aacute;nto de fiable es la valoraci&oacute;n de mi
                coche?
              </b>
            </span>
          </p>
          <p class="c0">
            <span>
              El precio que aparece en nuestro valorador no es el precio de tu
              coche, es{" "}
            </span>
            <span>
              el precio medio de un coche de esas caracter&iacute;sticas
              seg&uacute;n la oferta
            </span>
            <span class="c2">
              . Cada coche es un mundo y cada operaci&oacute;n de compraventa
              est&aacute; sometida a diferentes variables que nosotros no
              podemos tener en cuenta. El precio que damos es una
              orientaci&oacute;n, pero la &uacute;nica realidad fiable es la del
              mercado, la de la oferta y la demanda concreta y por ello en dos
              empresas distintas te ofrecer&aacute;n distintos precios. Nuestros
              precios sirven para que cada uno se forme una idea y que pueda
              estimar el precio de su coche en funci&oacute;n de lo bien que lo
              haya cuidado, de c&oacute;mo pueda certificar su buen
              mantenimiento, de los golpes que haya tenido y de la necesidad del
              comprador para comprar un coche de esas caracter&iacute;sticas.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
        </div>
      </div>
      <div className="spacer"></div>
    </div>
  );
}
