import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Redirector from "./components/Redirector";
import userContext from "./userContext";

export default function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const session = React.useState(queryParams.get("ref") ?? "");

  const getLocalStorageRef = () => {
    let ref = null;
    if (localStorage.getItem("informeKm77Ref"))
      ref = localStorage.getItem("informeKm77Ref");
    return ref;
  };

  const setLocalStorageRef = (ref) => {
    localStorage.setItem("informeKm77Ref", ref);
  };

  const getReferal = () => {
    const fromURL = queryParams.get("ref") ?? "";
    const fromStorage = getLocalStorageRef();

    if (fromURL !== "") {
      setLocalStorageRef(fromURL);
      return fromURL;
    }

    if (fromStorage !== null) {
      return fromStorage;
    }

    return "";
  };

  React.useEffect(() => {
    session[1](getReferal());
  }, []);

  return (
    <userContext.Provider value={session}>
      <ToastContainer />
      <Redirector />
    </userContext.Provider>
  );
}
