import React from "react";
import userContext from "../userContext";
import { useHistory, Link } from "react-router-dom";
import madivalogo from "../images/madivalogo.png";
import km77logo from "../images/km77-logo.png";
import infografia from "../images/informe_b.jpg";

export default function ComoLoHacemos() {
  const session = React.useContext(userContext);

  let history = useHistory();
  React.useEffect(() => {
  }, [session]);

  return (
    <div className="container main static">
      <Link to="/"><img src={madivalogo} alt="Madiva" className="logo right" /><img src={km77logo} alt="Madiva" className="logo right" /></Link>   

      <div className="car">
            <div className="container">
                <h1 className="text-highlight">¿Cómo lo hacemos?</h1>
                <h2>Infografía del proceso</h2>
                <img src={infografia} alt="Infografía" style={{width: "100%"}}  />
                
                <p className="back"><a href="/" onClick={(e) => {e.preventDefault();history.go(-1)}} ><i className="material-icons left">chevron_left</i> Volver</a></p>
            </div>
        </div>
        <div className="spacer"></div>


    </div>
  );
}
