import React from "react";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import { Link } from "react-router-dom";
import madivalogo from "../images/madivalogo.png";
import km77logo from "../images/km77-logo.png";

export default function Cancel() {
  return (
    <div className="m-8 font-mulish mb-10">
      <div className="flex items-center justify-center mb-8">
        <div className="text-3xl text-blue-800">INFORME DETALLADO</div>
        <div className="ml-2">
          <Link to="/" className="flex">
            <img src={madivalogo} alt="Madiva" className="w-14 mx-2" />
            <img src={km77logo} alt="Km77" className="w-14" />
          </Link>
        </div>
      </div>
      <div className="flex items-center justify-center mb-8">
        <div className="flex flex-col items-center">
          <div className="rounded-t-md h-10 w-full border border-yellow-600  bg-yellow-600 flex justify-center content-center">
            <div className="text-3xl text-center align-top font-bold text-white">
              <svg
                className="text-white mt-1 fill-current h-7 w-7"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
              </svg>
            </div>
          </div>
          <div className="rounded-b-md border border-yellow-600 text-xs w-24 text-center bg-yellow-600 text-white">
            Identificación
          </div>
        </div>
        <div className="h-1 w-10 border border-blue-800 bg-blue-800 flex justify-center content-center"></div>
        <div className="flex flex-col items-center">
          <div className="rounded-t-md h-10 w-full border border-yellow-600  bg-yellow-600 flex justify-center content-center">
            <div className="text-3xl text-center align-top font-bold text-white">
              <svg
                className="text-white mt-1 fill-current h-7 w-7"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
              </svg>
            </div>
          </div>
          <div className="rounded-b-md border border-yellow-600 text-xs w-24 text-center bg-yellow-600 text-white">
            Características
          </div>
        </div>
        <div className="h-1 w-10 border border-blue-800 bg-blue-800 flex justify-center content-center"></div>
        <div className="flex flex-col items-center">
          <div className="rounded-t-md h-10 w-full border border-yellow-600  bg-yellow-600 flex justify-center content-center">
            <div className="text-3xl text-center align-top font-bold text-white">
              <svg
                className="text-white mt-1 fill-current h-7 w-7"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
              </svg>
            </div>
          </div>
          <div className="rounded-b-md border border-yellow-600 text-xs w-24 text-center bg-yellow-600 text-white">
            Verificación
          </div>
        </div>
        <div className="h-1 w-10 border border-blue-800 bg-blue-800 flex justify-center content-center"></div>
        <div className="flex flex-col items-center">
          <div className="rounded-t-md h-10 w-full border border-yellow-600  bg-yellow-600 flex justify-center content-center">
            <div className="text-3xl text-center align-top font-bold text-white">
              <svg
                className="text-white mt-1 fill-current h-7 w-7"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
              </svg>
            </div>
          </div>
          <div className="rounded-b-md border border-yellow-600 text-xs w-24 text-center bg-yellow-600 text-white">
            Finalización
          </div>
        </div>
      </div>

      <Amplitude>
        <LogOnMount eventType="Page_Step4Error" />
        <div className="flex mt-20 justify-center">
          <div className="text-center">
            <div className="text-2xl text-blue-800">Informe cancelado!</div>
            <div className="text-blue-800 mt-4">
              Para cualquier duda escriba a:{" "}
              <a href="mailto:informes.segundamano@km77.com">informes.segundamano@km77.com</a>
            </div>
          </div>
        </div>
      </Amplitude>
    </div>
  );
}
